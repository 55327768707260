import doctorPerAPm from "../constants/doctorPerApm";

function mapApmToData(apmList, dataList) {
    dataList.forEach(data => {
      const fullName = `${data.doctorName?.toUpperCase()} ${data.doctorLastName?.toUpperCase()}`;
      
      const matchingApm = apmList.find(apm => {
        if(apm){
          const apmFullName = `${apm.NOMBRE?.toUpperCase()} ${apm.APELLIDO?.toString().toUpperCase()}`;
          return fullName.includes(apmFullName) || apmFullName.includes(fullName);
        }

      });
  
      if (matchingApm) {
        data.apm = `${matchingApm["APM NOMBRE"]}`;
      }
      else (data.apm = "Sin asignar")
    });
    
    return dataList;
  }

  export default mapApmToData