import React, { createContext, useContext, useState, useEffect } from 'react';
import { getUserRole } from '../Services/Users/getUserRole';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(false);
  const [role, setRole] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserRole = async () => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser &&  esFechaMayorAUnMes(new Date(storedUser.lastLoginAt)) === false) {
          setUser(storedUser);
          const userRole = await getUserRole(storedUser.uid);
          setRole(userRole);
          setIsLoading(false)
        }
        else{
            setUser(false)
            setIsLoading(false)
            localStorage.removeItem('user');

        }
      };
  
      fetchUserRole();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, role, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};



function esFechaMayorAUnMes(fecha) {
  
  const fechaActual = new Date();
  const diferenciaMilisegundos = fechaActual - fecha;
  const diferenciaDias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);
  return diferenciaDias > 30;
}

