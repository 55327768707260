import React from 'react';

const Resources = () => {
    return (
        <div>
            <h2>Resources</h2>
        </div>
    );
};

export default Resources;