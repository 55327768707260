import React from 'react';

const specialCases = [
    'ALARGADOR PESTAÑAS COVER WHIT BRUSHES',
    'ALARGADOR PESTAÑAS ENVASES',
    'ALARGADOR PESTAÑAS INNER',
    'VALVULAS GATILLOS',
    'POMOS S/I/C/T PLASTICO TUBE 30ML',
    'SILVER PUMP FOR 30ML PERAL ACRYLIC BOTTLE',
    'VALVULAS CREAM PUMP 24/410',
    'BOTELLA NUEVO S/I 30ML PEARL ACRYLIC BOTTLE',
];

const TableRow2 = ({ tableRowsData, active }) => {
    // Extract the button-related data and other data separately
    const buttons = tableRowsData.slice(-1)[0]; // The last element which contains the buttons
    const otherData = tableRowsData.slice(0, -1); // All elements except the last one

    return (
        <tr>
            <td className="">
                <div className='flex items-center bg-none'>
                    {buttons}
                </div>
            </td>
            {otherData.map((tableRowData, index) => {
                if (index === 0) {
                    return (
                        <td className="" key={index}>
                            <div className='flex items-center bg-none'>
                                {tableRowData}
                                {specialCases.includes(tableRowData.trim()) && (
                                    <span title='Producto viejo' className='animation-pulse1 mx-4 w-3 h-3 bg-red-500 rounded-full'></span>
                                )}
                                {active && (
                                    <span title='Movimiento pendiente' className='animation-pulse mx-4 w-3 h-3 bg-yellow-400 rounded-full'></span>
                                )}
                            </div>
                        </td>
                    );
                } else if ((parseInt(tableRowsData[3]) < parseInt(tableRowsData[2])) && index === 3 && !isNaN(parseInt(tableRowsData[2]))) {
                    return (
                        <td className="text-[#B91C1C]" key={index}>
                            <p
                                title='La cantidad es inferior a la cantidad minima establecida'
                                className='font-semibold border-white border-solid border-[1px] cursor-pointer bg-[#FEE2E2] py-[2px] rounded-[32px] m-1 text-center'
                            >
                                {tableRowData}
                            </p>
                        </td>
                    );
                } else if (index === 3 && !isNaN(parseInt(tableRowsData[2]))) {
                    return (
                        <td key={index} className="text-[#3e6051]">
                            <p
                                title='Todo Ok'
                                className={`bg-[#c4fcdc] border-white border-solid border-[1px] rounded-[32px] font-semibold m-1 py-[2px] text-center cursor-pointer ${tableRowData === 'SALIDA' ? 'bg-red-400 text-white' : ''}`}
                            >
                                {tableRowData}
                            </p>
                        </td>
                    );
                } else if (index === 4 && !isNaN(parseInt(tableRowsData[3])) && (tableRowData === 'SALIDA' || tableRowData === 'ENTRADA')) {
                    return (
                        <td key={index} className="text-[#3e6051]">
                            <p
                                title='Todo Ok'
                                className={`bg-[#c4fcdc] border-white border-solid border-[1px] rounded-[32px] font-semibold m-1 py-[2px] text-center cursor-pointer ${tableRowData === 'SALIDA' ? 'bg-red-400 text-white' : ''}`}
                            >
                                {tableRowData}
                            </p>
                        </td>
                    );
                } else {
                    return <td key={index}>{tableRowData}</td>;
                }
            })}
        </tr>
    );
};

export default TableRow2;
