import React from 'react';
import { MdLocalPharmacy, MdSpaceDashboard  } from 'react-icons/md';
import { IoIosPaper } from "react-icons/io";
import { RiProductHuntFill, RiProfileFill } from 'react-icons/ri';
import { Link, Outlet } from 'react-router-dom';
import LinkComponents from '../../components/navbar/LinkComponents';
import UserNavbar from '../../components/UserNavbar';
import logo from '../../Assets/logo.png';
import DetailsComponent from '../../components/navbar/DetailsComponent';
import { useAuth } from '../../hooks/useAuth';
const Dashboard = () => {
    const {role} = useAuth()
    return (
        <div className="drawer drawer-mobile">
            <input id="dashboard" className="drawer-toggle" />
            <div className="drawer-content relative z-50">

                <UserNavbar />
                <Outlet />
            </div>
            <div className="drawer-side lg:bg-white md:bg-white w-52 lg:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
                <label htmlFor="dashboard" className="drawer-overlay"></label>
                <div className="flex flex-col justify-between">
                    <nav className="flex flex-col mt-6 space-y-2">
                        <Link className="text-xl font-semibold uppercase flex items-center mb-8" to='/'>
                            <img className='w-20 ml-4' src={logo} alt="logo" />
                        
                        </Link>

                        {['superAdmin', 'comercialLeader', 'apms'].includes(role.role) && <LinkComponents to={''} icon={<MdSpaceDashboard className='text-lg' />} name={'Dashboard'} />}
                        {['pollito'].includes(role.role) && <LinkComponents to={'products/non-pharmacy'} icon={<RiProfileFill className='text-lg' />} name={'Movimientos'} />}
                        

                       {['superAdmin', 'stockLeader'].includes(role.role) &&  <DetailsComponent
                            icon={<RiProductHuntFill className='text-lg' />}
                            name={'Productos'}
                            subMenus={
                                [
                                    <LinkComponents
                                        to={'products/pharmacy'}
                                        icon={<MdLocalPharmacy className='text-lg' />}
                                        name={'Productos'} />,

                                    <LinkComponents
                                        to={'products/non-pharmacy'}
                                        icon={<RiProfileFill className='text-lg' />}
                                        name={'Movimientos'} />
                                ]
                            } />}
                            {['superAdmin', 'stockLeader'].includes(role.role) && <LinkComponents to={'stock/admin'} icon={<MdSpaceDashboard className='text-lg' />} name={'M. Pendientes'} />}
                            {['superAdmin', 'comercialLeader', 'apms'].includes(role.role) && <LinkComponents to={'recetaDigital'} icon={<IoIosPaper  className='text-lg' />} name={'Receta Digital'} />}
                            {['superAdmin', 'comercialLeader', 'stockLeader'].includes(role.role) && <LinkComponents to={'mapaDeposito'} icon={<IoIosPaper  className='text-lg' />} name={'Mapa Depósito'} />}

                      
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;