import React, { useContext, useState, useEffect } from "react";
import TableRow2 from "../../../components/TableRow2";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import AcceptButton from "../../../components/Buttons/AcceptButton";
import { BACKEND_URL } from "../../../constants/constants";
import { ReloadDataContext } from "../../../contexts/reloadDataContext";
import SelectDataPersonalized from "../../../components/molecules/filterSelect.js";
import EditButtonMoviment from "../../../components/Buttons/EditButtonMoviment.js";
import { BiEdit } from "react-icons/bi";

const StockAdmin = () => {
  const [moviments, setMoviments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterRemito, setFilterRemito] = useState([]);
  const [filterDate, setFilterDate] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);

  const { reloadPending, setReloadPending } = useContext(ReloadDataContext);

  const tableHeadItems = [
    "Producto", "Cantidad", "Nº de remito", "Fecha", "Tipo de Movimiento",
    "Responsable Interno", "Tipo de proveedor", "Nombre de proveedor", "Nombre de Destinatario", "Orden de producción",
   "Producción", "Fecha de Vencimiento",
  ];

  // Maneja la selección de filas, agregando todo el objeto del movimiento seleccionado
  const handleRowCheckboxChange = (moviment) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.some((row) => row.id === moviment.id)
        ? prevSelectedRows.filter((row) => row.id !== moviment.id)
        : [...prevSelectedRows, moviment]
    );
  };

  // Maneja la selección de todas las filas
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(moviments);
    } else {
      setSelectedRows([]);
    }
  };

  // Acepta todos los productos seleccionados
  const handleAcceptSelected = () => {
    selectedRows.forEach((moviment) => {
      // Lógica para aceptar el producto (hacer llamada a la API)
      fetch(`${BACKEND_URL}/moviments/accept/${moviment.id}`, { method: "POST" })
        .then(() => setReloadPending((prev) => !prev), setSelectedRows([])); // Recargar los datos después de aceptar
    });
  };

  // Elimina todos los productos seleccionados
  const handleDeleteSelected = () => {
    selectedRows.forEach((moviment) => {
      // Lógica para eliminar el producto (hacer llamada a la API)
      fetch(`${BACKEND_URL}/moviments/deletePending/${moviment.id}`, { method: "DELETE" })
        .then(() => setReloadPending((prev) => !prev), setSelectedRows([])); // Recargar los datos después de eliminar
    });
  };

  useEffect(() => {
    fetch(`${BACKEND_URL}/moviments/pending`)
      .then((res) => res.json())
      .then((moviments) => {
        const uniqueRemitos = new Set(moviments.map((moviment) => moviment.referNumber));
        const uniqueDates = new Set(moviments.map((moviment) => moviment.date.split(",")[0]));

        setFilterRemito([...uniqueRemitos]);
        setFilterDate([...uniqueDates]);
        setMoviments(moviments);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [reloadPending]);

  const tableHead = (
    <tr>
      <th>
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={selectedRows.length === moviments.length}
        />
      </th>
      {tableHeadItems.map((tableHeadItem, index) => (
        <th key={index} className="text-xs md:text-2xs lg:text-md">
          {tableHeadItem}
        </th>
      ))}
    </tr>
  );

  console.log(selectedRows)

  return (
    <section className="p-4 mt-20">
      <div className="grid grid-cols-2 gap-x-4 gap-y-2">
        <div className="mb-4 mt-[-16px] z-[100] flex flex-col gap-2">
          <p className="text-xl">Numero de remito</p>
          <SelectDataPersonalized options={filterRemito} setSelectedFilters={setFilterRemito} />
        </div>
        <div className="mb-4 mt-[-16px] z-[100] flex flex-col gap-2">
          <p className="text-xl">Fecha de remito</p>
          <SelectDataPersonalized options={filterDate} setSelectedFilters={setFilterDate} />
        </div>
      </div>
      {
        moviments.length > 0 ? (
          <div className="flex justify-between my-4">
          <button onClick={handleAcceptSelected} className="btn btn-success">
            Aceptar Seleccionados
          </button>
          <button onClick={handleDeleteSelected} className="btn btn-danger">
            Eliminar Seleccionados
          </button>
        </div>
        ) : null
      }
      {loading ? (
        <table className="table1">
          <tbody className="table-tbody">
            <tr>
              <td className="loading">
                <div className="bar"></div>
              </td>
            </tr>
          </tbody>
        </table>
      ) : moviments.length > 0 ? (
        <table className="table table-zebra table-compact">
          <thead>{tableHead}</thead>
          <tbody>
            {moviments
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .map((moviment, index) => (
                <TableRow2
                  key={index}
                  tableRowsData={[
                    moviment.product,
                    moviment.quantity,
                    `X-0001-0000${moviment.referNumber}`,
                    moviment.date,
                    moviment.typeOfMoviment,
                    moviment.responsable,
                    moviment.typeOfAdressee,
                    moviment.suplierName,
                    moviment.adresseeName,
                    moviment.productionOrder,
                    moviment.lot,
                    moviment.expiration,
                    <span className="flex items-center gap-x-4">
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows.some((row) => row.id === moviment.id)}
                          onChange={() => handleRowCheckboxChange(moviment)}
                        />
                      </td>
                      <DeleteButton
                        deleteURL={"/moviments/deletePending/"}
                        itemId={moviment.id}
                      />
                      <AcceptButton
                        acceptURL={"/moviments/accept/"}
                        itemId={moviment.id}
                      />
                      <label
                        onClick={() => setUpdateModal(moviment)}
                        htmlFor="update-pharmacy-product"
                        className={`gap-x-2 cursor-pointer modal-button z-10 block p-1 text-blue-700 transition-all bg-blue-100 border-2 border-white rounded-full active:bg-blue-50 hover:scale-110 focus:outline-none focus:ring`}
                      >
                        <BiEdit />
                      </label>
                      {updateModal && <EditButtonMoviment moviment={updateModal} setModal={setUpdateModal}  />}
                    </span>,
                  ]}
                />
              ))}
          </tbody>
        </table>
      ) : (
        <div className="text-center text-lg mt-4">No hay movimientos pendientes</div>
      )}
    </section>
  );
};

export default StockAdmin;
