
import DigitalRecipesGraphicProvider from '../contexts/digitalRecipesContext';
import RecetasPorAPMDashboard from './digitalRecipesGraphic';

const RecetasPorAPMTemplate = ({  }) => {


  return (
    <>
    <DigitalRecipesGraphicProvider>
      <RecetasPorAPMDashboard/>

    </DigitalRecipesGraphicProvider>
    </>
  );
};

export default RecetasPorAPMTemplate;