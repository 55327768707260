import React, { useContext } from "react";
import PrintButton from "../../../components/Buttons/PrintButton";
import NewButton from "../../../components/Buttons/NewButton";
import { useState } from "react";
import { useEffect } from "react";
import RefreshButton from "../../../components/Buttons/RefreshButton";
import TableRow from "../../../components/TableRow";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import DashboardPageHeading from "../../../components/headings/DashboardPageHeading";
import { BACKEND_URL } from "../../../constants/constants";
import NewMoviment from "../../../components/modals/newMoviment";
import { createExcel } from "../../../hooks/useCreateExcel";
import { ReloadDataContext } from "../../../contexts/reloadDataContext";
import SelectDataPersonalized from "../../../components/molecules/filterSelect.js";



const Moviments = () => {
	const tableHeadItems = [
		"Producto",
		"Cantidad",
		"Nº de remito",
		"Fecha",
		"Tipo de Movimiento",
		"Responsable Interno",
		"Tipo de proveedor",
		"Nombre de proveedor",
		"Nombre de Destinatario",
		"Orden de producción",
		"Producción",
		"Fecha de Vencimiento",
	];
	


	const [createModalState, setCreateModalState] = useState(false);

	const tableHead = (
		<tr>
			{tableHeadItems?.map((tableHeadItem, index) => (
				<th key={index} className="text-xs md:text-2xs lg:text-md">
					{tableHeadItem}
				</th>
			))}
		</tr>
	);

	useEffect(() => {
		if (createModalState) {
			document.getElementsByClassName("drawer-content")[0].style.overflow = "hidden"; // Para asegurar que el scrollbar desaparezca
		} else {
			document.getElementsByClassName("drawer-content")[0].style.overflow = ""; // Restaurar el scrollbar
		}

		/*     return () => {
      document.getElementsByClassName('drawer-content')[0].style.overflow = '';  // Asegurar restauración al desmontar el componente
    }; */
	}, [createModalState]);

	const [moviments, setMoviments] = useState([]);
	const [products, setProducts] = useState([]);
	console.log(moviments);

	const { reloadMovement } = useContext(ReloadDataContext);

	const createModalToggle = () => {
		setCreateModalState(!createModalState);
	};

	useEffect(() => {
		fetch(`${BACKEND_URL}/products/all`)
			.then((res) => res.json())
			.then((products) => {
				setProducts(products);
			});
	}, []);

	const [categoriesRemito, setCategoriesRemito] = useState([]);
	const [filtersRemito, setFiltersRemito] = useState([]);
	const [categoriesProduct, setCategoriesProduct] = useState([]);
	const [filtersProduct, setFiltersProduct] = useState([]);


	const setSelectedFiltersRemito = (selectedOptions) => {
		setFiltersRemito(selectedOptions);
	};


	const setSelectedFiltersProduct = (selectedOptions) => {
		setFiltersProduct(selectedOptions);
	};

	useEffect(() => {
		// Obtener todos los movimientos
		fetch(`${BACKEND_URL}/moviments/getAll`)
		  .then((res) => res.json())
		  .then((data) => {
			let allMovements = data.data;
			
			let set = new Set(allMovements.map((product) => product.referNumber));
			let arraySinDuplicados = [...set];
			setCategoriesRemito(arraySinDuplicados);
	  
			let set2 = new Set(allMovements.map((product) => product.product));
			let arraySinDuplicados2 = [...set2];
			setCategoriesProduct(arraySinDuplicados2);
	  
			// Obtener los movimientos pendientes y combinarlos
			fetch(`${BACKEND_URL}/moviments/pending`)
			  .then((res) => res.json())
			  .then((pendingData) => {
				let combinedMovements = [ ...pendingData, ...allMovements];
				setMoviments(combinedMovements);
			  });
		  });
	  }, [reloadMovement]);


	return (
		<section className="p-4 mt-16">
			<DashboardPageHeading
				name="Últimos Movimientos"
				value={moviments.length}
				buttons={[
					<NewButton title={"Cargar nuevo"} modalId="create-new-product" onClick={createModalToggle} />,
					<div
						onClick={() =>
							createExcel(
								moviments,
								`Movimientos a fecha ${new Date().getDate()} ${new Date().getMonth() + 1} ${new Date().getFullYear()}`
							)
						}>
						<PrintButton />
					</div>,
				]}
			/>
			<div className="grid grid-cols-2 gap-x-4 gap-y-2">
				<div className="mb-4 mt-[-16px] z-[100] flex flex-col gap-2">
					<p className="text-xl">Nombre producto</p>
					<SelectDataPersonalized options={categoriesProduct} setSelectedFilters={setSelectedFiltersProduct}/>
				</div>
				<div className="mb-4 mt-[-16px] z-[100] flex flex-col gap-2">
					<p className="text-xl">Numero remito</p>
					<SelectDataPersonalized options={categoriesRemito} setSelectedFilters={setSelectedFiltersRemito}/>
				</div>
			</div>

			{/* create new pharmacy product purchase */}
			{createModalState && (
				<>
					<NewMoviment products={products} createModalToggle={createModalToggle} />
				</>
			)}
			{/* update a pharmacy product */}

			{/* Purchased Table */}

			{moviments.length > 0 ? (
				<table className="table table-zebra table-compact">
					<thead>{tableHead}</thead>
					<tbody>
						{moviments.filter((x) => {
						if (filtersRemito.length > 0 && filtersProduct.length > 0) {
							return filtersRemito.includes(x.referNumber) && filtersProduct.includes(x.product);
						  } else if (filtersRemito.length > 0) {
							return filtersRemito.includes(x.referNumber);
						  } else if (filtersProduct.length > 0) {
							return filtersProduct.includes(x.product);
						  } else {
							return true; // Devuelve todos los movimientos si ambos filtros están vacíos
						  }
					})
							.map((moviment, index) => (
								<TableRow
									key={index}
									tableRowsData={[
										moviment.product,
										moviment.quantity,
										`X-0001-0000${moviment.referNumber}`,
										moviment.date,
										moviment.typeOfMoviment,
										moviment.responsable,
										moviment.typeOfAdressee,
										moviment.suplierName,

										moviment.adresseeName,
										moviment.productionOrder,
										
										moviment.lot,
										moviment.expiration,

										<span className="flex items-center gap-x-1">
											{/* <EditButton /> */}
											<DeleteButton
												deleteApiLink="https://stringlab-ims-server.herokuapp.com/api/purchases/pharmacy/"
												deleteURL={"/moviments/delete/"}
												itemId={moviment.id}
												name={moviment.tradeName}
											/>
										</span>,
									]}
									active={moviment.active}
								/>
							))}
					</tbody>
				</table>
			) : (
				<table className="table1">
					<tbody className="table-tbody">
						<tr>
							<td className="loading">
								<div className="bar"></div>
							</td>
						</tr>
					</tbody>
				</table>
			)}
		</section>
	);
};

export default Moviments;
