export const transfers = [
    {
      "localidad": "ACASSUSO 6382",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA GAONA 1486",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA GRAL. PAZ 4998",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA MONTES DE OCA 954",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA MOSCONI 3300",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA NAZCA 1876",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA NAZCA 2102",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA PUEYRREDON 1673",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA RIVADAVIA 2463",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA. SANTA FE 4024",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA.GAONA NRO.1915",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVELLANEDA NRO. 896",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVELLANEDA NRO. 896",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVENIDA FRANCISCO BEIRO 3562/64/6",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVENIDA RIVADAVIA 6854",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVENIDA SAENZ 1187",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "BOMPLAND 1418",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "C.A.B.A",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "C.A.B.A",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "C.A.B.A.",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CABA",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Calle: GUALEGUAYCHU Nro.: 448",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CAPITAL FEDERAL",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Carranza A. J. 2002",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CHARCAS 3099",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CIUDAD AUTONOMA",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CIUDAD AUTONOMA",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CIUDAD AUTONOMA",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CNEL M CHILAVERT 6461",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CNEL MARTINIANO CHILAVERT 6461",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CNEL MARTINIANO CHILAVERT 6461",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CNEL MARTINIANO CHILAVERT 6461",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CONESA 2239",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "DOMINGO F SARMIENTO 1490",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "DR ROMULO S NAON 2357",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "DR TOMAS DE ANCHORENA 1260",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ECUADOR 918",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "EMILIO MITRE 1404",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ENTRE RIOS 799",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ESMERALDA 599",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ESTOMBA NRO.278",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Formosa 302",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "FRANCISCO BILBAO 5868",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "FRANCISCO BILBAO 5868",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "GRAL JOSE G ARTIGAS 4702",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "GRAL JUAN JOSE VIAMONTE 2070",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "GUAMINI 4867",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "GUEVARA 1541",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "IRIARTE 2200",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "JORGE NEWBERY 1601",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "JOSE HERNANDEZ 2237",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "JOSE MARIA MORENO 245",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "JULIAN ALVAREZ 601",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "LLAVALLOL NRO. 5066",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Marcelo T. De Alvear N 1502",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MARCOS SASTRE 2419",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MARIANO ACHA 2719",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MIRANDA NRO. 3999",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "OLAVARRIA NRO 443",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PAZ GRAL AV. 4998",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PEDRO CONDE 815",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ALEJAND. M.CERVANTES 5402",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Alsina 1801",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ALVAREZ JONTE 4873",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ALVAREZ JONTE 4873",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ALVAREZ THOMAS 1555",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ARCE 516",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ARENALES 1302",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CABILDO 1248",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CABILDO 1274",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CABILDO 1631",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CABILDO 2171",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CABILDO 2685",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CABILDO 2779",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CALLAO 1379",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CORRIENTES 901",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  FERNANDEZ DE ENCISO 3947",
      "zona": "CABA NORTE",
      "apm": "CABA NORTE"
    },
    {
      "localidad": "AV  FERNANDEZ DE ENCISO 3979",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  FERNANDEZ DE ENCISO 3979",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SANTA FE 3557",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  TRIUNVIRATO 4451",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  TRIUNVIRATO 4602",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  TRIUNVIRATO 4900",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV ALVAREZ JONTE 5402",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV ALVAREZ THOMAS 284",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av Balbin 3936",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV CABILDO 1528",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV CABILDO 1631/37",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV CABILDO 2171",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV CABILDO 4599",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV CRAMER 1963",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV DE LOS INCAS 4960",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV ENTRE RIOS 1177",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV FEDERICO LACROZE 1701",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV FEDERICO LACROZE 2495",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV FEDERICO LACROZE 2599",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV FEDERICO LACROZE 3951",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV FEDERICO LACROZE 4125",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV FERNANDEZ DE ENCISO 3947",
      "zona": "CABA NORTE",
      "apm": "CABA NORTE"
    },
    {
      "localidad": "AV FRANCISCO BEIRO 5280",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV GARCIA DEL RIO 4097",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV GRAL JUAN M DE PUEYRREDON 1845",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV GRAL LAS HERAS 2699",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV MONROE 4968",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV QUINTANA 392",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV SAN JUAN 2501",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV TRIUNVIRATO 4602",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV TRIUNVIRATO 4602",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Alvarez Jonte 5402",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Alvarez Thomas 2600",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Corrientes 2890",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Cramer N 1963/65",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Entre Rios 205",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. FEDERICO LACROZE 2599",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. LINCOLN 4497. ESQ. VALLEJOS",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. MONROE Nº 4770",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Mosconi 3300",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. TRIUNVIRATO 4495",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. TRIUNVIRATO 4602",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV.CABILDO 2171",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA ALVAREZ JONTE 3202",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA ALVAREZ JONTE 3721",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA CABILDO 1566",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA CABILDO 2171",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA CABILDO 3111",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA CONGRESO 2486",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA CONGRESO 5101",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA FEDERICO LACROZE 3951 UF 6 P",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA JURAMENTO 2739",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA MONROE 3592",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA MONROE 3592",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA. MONROE 5091",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA.CABILDO 1566",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA.CABILDO 2178",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA.CONSTITUYENTES 6163",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA.CRAMER 1963/65 PB",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA.JURAMENTO 2739",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA.JURAMENTO 2741",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA.TRIUNVIRATO 3741/49",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVENIDA CONGRESO 2486",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVENIDA CORDOBA 2501",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "BERMUDEZ 2461/63 P.B.",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Bonpland 1513",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "BULNES 1770",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CABILDO 2171",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Cabildo 2178",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CALLAO 299",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CAMPANA 2502",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CIUDAD DE LA PAZ 3299",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CLAUDIO MAMERTO CUENCA 2719",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CLAUDIO MAMERTO CUENCA 2762",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CLAUDIO MAMERTO CUENCA 2901",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CONGRESO 2486",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CONGRESO 2486",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CRAMER 1963/65",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Cramer 2101",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CUENCA 2762",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CUENCA 2762",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CUENCA 2762",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "DEVOTO",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ECHEVERRIA 1470/72",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ECHEVERRIA NRO.3187",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ESTEBAN ECHEVERRIA 1472",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "FEDERICO LACROZE 1701",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "FERNANDEZ DE ENCISO 3947",
      "zona": "CABA NORTE",
      "apm": "CABA NORTE"
    },
    {
      "localidad": "Fernandez Enciso 3979",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "JUNCAL 2733",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "JURAMENTO 2002",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "JURAMENTO 2484",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "LA PAMPA 2978",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "LA PAMPA 3400",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "LAS HERAS 2318",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "LAS HERAS 2699",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "LAVALLE NRO. 1401",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "M T DE ALVEAR 2045",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MANSILLA 2601 PTA BAJA",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MANUELA PEDRAZA 2044",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MANUELA PEDRAZA 4299",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Matienzo 1714",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MAURE 1732",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MEDRANO 1168/72",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MOLDES 2502",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MONROE 2802",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MONROE 4968",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PARAGUAY 2701",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PARAGUAY 5002 PLANTA BAJA Y E.PIS",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PTE M T DE ALVEAR 1502",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Pte. J.D. Peron 2319",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Rincon N 96",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "SANABRIA 3354",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "SANCHEZ DE BUSTAMANTE 1666",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "SUCRE 2092",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "TRIUNVIRATO NRO. 4499",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "TRIUNVIRATO NRO. 4499",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "TRIUNVIRATO NRO. 4499",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "TTE BENJAMIN MATIENZO 1714",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "URUGUAY 1090",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "URUGUAY 477/9",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "VIRREY AVILES 3371",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ALMIRANTE BROWN 1361",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ALMIRANTE BROWN 1361",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ALVEAR MARCELO T. DE 2301",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AUSTRIA 1839",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CALLAO 299",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CASEROS 1000",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CASEROS 1990",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CASEROS 2980",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CASEROS 2980",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CASEROS 2980",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CASEROS 2980",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CERVIÑO 3365",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CORDOBA 2401",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CORDOBA 2401",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CORDOBA 2401",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CORDOBA 533",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CORDOBA 533",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CORRIENTES 2345",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CORRIENTES 4194",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  CORRIENTES 4647",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  DEL LIBERTADOR 8322",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  DIRECTORIO 1281",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  DIRECTORIO 1649",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  DIRECTORIO 2",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  ENTRE RIOS 1177",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  ENTRE RIOS 196",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  GAONA 2400",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  INDEPENDENCIA 1301",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  INDEPENDENCIA 2002",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  INDEPENDENCIA 3002",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  LA PLATA 1402",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  LA PLATA 2401",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  NAZCA 1982",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SAN MARTIN 1673",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SAN MARTIN 2159",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SAN MARTIN 2711",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SAN MARTIN 3200",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SANTA FE 2399",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SANTA FE 2453",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SANTA FE 2642",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SANTA FE 3000",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SANTA FE 3350",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SANTA FE 3944",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SANTA FE 4288",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SANTA FE 4402",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV  SANTA FE 802",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV ALBERDI 202",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV ALBERDI 4301",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV ALBERDI 5281",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV ALBERDI 6208",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV ALBERDI 7461",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV ALBERDI 7461",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV ALTE G BROWN 711",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV ANGEL GALLARDO 964",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV B MITRE 475",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BELGRANO 1200",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 11356",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 11420",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 2215",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 2398",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 3081",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 3600",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 3823",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 3913",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 4065",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 4399",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 4451",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 4634",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 4953",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 5360",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 5375",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 5415",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 5892",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av Caseros 1902",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV CASEROS 1990",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV CASEROS 2915",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av Caseros 2980",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av Caseros 2980",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av Caseros 2980",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV CASEROS 999",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV CERVINO 4716",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV CNEL DIAZ 2179",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "av cordoba 1353",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV CORDOBA 1402",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV CORDOBA 1467",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV DIRECTORIO 1281",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV DIRECTORIO 2",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av Directorio 2601",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV DIRECTORIO 4709 PB,1,2,3 PISO",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV DR JOSE M RAMOS MEJIA 1340",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV DR NICOLAS AVELLANEDA 439",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av F. Lacroze 3938",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV FEDERICO LACROZE 2276",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV FEDERICO LACROZE 2276/78",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV GRAL JUAN M DE PUEYRREDON 1460",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV GRAL LAS HERAS 2318",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV GRAL LUIS MARIA CAMPOS 1267",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV GRAL MOSCONI 2799",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV INDEPENDENCIA 2002",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV INDEPENDENCIA 3347",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV J DE GARAY 3101",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av J.B. Alberdi 5302",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV JERONIMO SALGUERO 3080",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV LA PLATA 1402",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV LA PLATA 2401",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV LA PLATA 2501",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV MONTES DE OCA 1800",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV MONTES DE OCA 898",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV MONTES DE OCA 954",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV PRES MANUEL QUINTANA 392",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV PUEYRREDON 1845",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av Rivadavia 1911",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV RIVADAVIA 2101 ESQ JUNIN 12",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV RIVADAVIA 2754",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV RIVADAVIA 3600",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV RIVADAVIA 4399",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV RIVADAVIA 4451/53/55",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV RIVADAVIA 5360",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av Rivadavia 5444",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV RIVADAVIA 5892",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV RIVADAVIA 7222",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV SANTA FE 2528",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av Santa Fe 4402",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV SANTA FE 4820",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV STA FE 3557",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Alvarez Thomas 1555",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Avellaneda 2014",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Cordoba 1402",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Cordoba 3902",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. CORRIENTES 4617",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. DIRECTORIO 1281",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. DIRECTORIO 1649",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. ESCALADA 2399",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Honorio Pueyrredon 801",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. INdependencia 1301",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. INDEPENDENCIA 2002",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. JUAN B ALBERDI 6102",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. JUAN B ALBERDI 6102",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. JUAN B ALBERDI 6102",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. JUAN DE GARAY 3101",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Las Heras 2699",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Las Heras 2699",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Las Heras 2699",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Las Heras 3299",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. LIBERTADOR N° 8404",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Quintana 390",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Quintana 390",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. RIVADAVIA 2398",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. RIVADAVIA 5014",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Rivadavia 5415",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Rivadavia 5444",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. RIVADAVIA 5892",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Rivadavia 6728",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. SAN MARTIN 6893/9",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. SAN MARTIN 6893/9",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. SAN MARTIN 6893/9",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. SAN MARTIN 6893/9",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. San Martin 6899",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. San Martin 6899",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. San Martin 6899",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av. Santa Fe 2399",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV. SANTA FE 5395",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV.BELGRANO 3049/53",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV.DIAZ VELEZ NRO.5101",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av.Entre Rios 205",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV.RIVADAVIA 5014",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV.RIVADAVIA 5014",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV.RIVADAVIA 5014",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV.RIVADAVIA 5014",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Av.Santa Fe 2642",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA CACEROS 1902",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA CASEROS 1990",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA CASEROS 2980",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA CASEROS 2980",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA CORDOBA 3199",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA DIRECTORIO 1649",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA DIRECTORIO 2890",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA ENTRE RIOS 205",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA ENTRE RIOS 299",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA F.F.DE LA CRUZ 3342",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA FRANCISCO BEIRO 5402",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA FRANCISCO BEIRO 5402",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA INDEPENDENCIA 1810",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA INDEPENDENCIA NÂº 2002",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA INDEPENDENCIA Nº 2002",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA JUAN BAUTISTA ALBERDI 2188",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA JUAN BAUTISTA ALBERDI 2188",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA JUAN BAUTISTA ALBERDI 2188",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA JUAN BAUTISTA ALBERDI 2188",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA LA PLATA 283",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA MOSCONI 3300",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA NAZCA 1876",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA RIVADAVIA 4544",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA RIVADAVIA 4718",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA RIVADAVIA 5014",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA RIVADAVIA 6327 P.B.",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA RIVADAVIA 6854",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA RIVADAVIA 8099",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA RIVADAVIA 8418",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA RIVADAVIA 8509",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA RIVADAVIA 8784/8800 ESQ LACA",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA RIVADAVIA 9123",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA RIVADAVIA 9902",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA SAN MARTIN 1673",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA SAN MARTIN 2802",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA SAN MARTIN 7277",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA SANTA FE 3000",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA SANTA FE 4228",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA SCALABRINI ORTIZ 361",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA SCALABRINI ORTIZ 361",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA SEGUROLA 1574",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA.CORONEL DIAZ 2299",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA.CORRIENTES 5270",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA.J.B.ALBERDI 6102",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVDA.RIVADAVIA NRO.5892",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVENIDA CORDOBA 2064",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVENIDA OLAZABAL NRO.5297",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AVENIDA RIVADAVIA 4399",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "BOEDO 953/955",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "BONPLAND 1631",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "BOYACA NÂ° 508",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "BRASIL 1299",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CABELLO 3701",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Cabello 3701",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CABRERA JOSE 3931",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "CHARCAS 4299",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "DEFENSA 201",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ENTRE RIOS 205",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "ESTEBAN ARCE 516",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Federico Lacroze 2495",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "GRAL GUEMES 3002",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "GRAL GUEMES 3200",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "GRAL GUEMES 3500",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "GRAL GUEMES 3787",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "GRAL GUEMES 4799",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "GRAL JUAN LAVALLE 1401",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "GRAL URQUIZA 624",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "J B ALBERDI 202",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "JERONIMO SALGUERO 3212",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "JOSE GUEVARA 1541",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "JUAN B. JUSTO 2713",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "JUNCAL 2799",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "LARREA 1249",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "LARREA NRO.1249",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Libertad 1092",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Luis S. Pena 475",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "M. T. DE ALVEAR 2301",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MANSILLA 2601",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MARCELO DE ALVEAR 2301",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MARCELO T DE ALVEAR 2408",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MARCELO T. DE ALVEAR 2301",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MONTES DE OCA 1229",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MONTEVIDEO 1183",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "MORENO  3059",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PARAGUAY 1402",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PARAGUAY 3260",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PARAGUAY 3950",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PARAGUAY 4979",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PARAGUAY 5002",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PARAGUAY 5301",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PARAGUAY 615",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PEDRO GOYENA 69",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Pedro Moran 4601",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "POTOSI 4100",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PRES JOSE E URIBURU 1223",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PRES JOSE E URIBURU 1223",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PTE H YRIGOYEN 1476",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PTE M T DE ALVEAR 2301",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PTE M T DE ALVEAR 2301",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "PTE M T DE ALVEAR 2408",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "RECONQUISTA 526",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "REPUBLICA DOMINICANA 3358",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "RINCON 96",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "RIVADAVIA 5415",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Rivadavia 5892",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "RIVADAVIA 901",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "SANTA FE 2166",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "SANTA FE 2399",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "SANTOS DUMONT 3448",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "SARMIENTO 2102",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "SUIPACHA 414 PISO 1",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "TALCAHUANO 1036",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "TEODORO GARCIA 2377",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "TRELLES 2502",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "TRELLES 2502",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "TTE GRAL JUAN D PERON 2319",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "TTE GRAL JUAN D PERON 4101",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "TTE GRAL JUAN DOMINGO PERON 4185",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "TTE.GRAL.J.DOM.PERON 4500",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "TUCUMAN 917",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "URUGUAY 479",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "Varela 794",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "VARELA 999",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "VENEZUELA 802",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "FREIRE 2313",
      "zona": "CABA NORTE",
      "apm": "CABA NORTE"
    },
    {
      "localidad": "AV  DIRECTORIO 2324",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV  DIRECTORIO 4801",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV ALBERDI 4101",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV ALBERDI 6102",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV ALBERDI 6151",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV ALBERDI 6294",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV ALBERDI 7461",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV ANTONIO ALVAREZ JONTE 3202",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV ANTONIO ALVAREZ JONTE 3721",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV ANTONIO ALVAREZ JONTE 4683",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 6728",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 6854",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 7363",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 8302",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 8503",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 8749",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV EVA PERON 4256",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV EVA PERON 4802",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV EVA PERON 4894",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Av Eva Peron 5340",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Av Eva Peron 5340",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV FERNANDEZ DE LA CRUZ 6245",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV FRANCISCO BEIRO 3160",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV FRANCISCO BEIRO 3562",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV FRANCISCO BEIRO 3602",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV FRANCISCO BEIRO 4594",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV GRAL JOSE MARIA PAZ 10720",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV OLIVERA 139",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Av Olivera 139",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV SEGUROLA 1200",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Av. Avellaneda 212",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Av. Avellaneda 212",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Av. Directorio 4701",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Av. Directorio 4801",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Av. Eva Peron 4849",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Av. Juan Bautista Alberdi 6151",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA DIRECTORIO 4701",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA EVA PERON 2590",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA JUAN B ALBERDI 7399 ESQ SALA",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA OLIVERA 139",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA OLIVERA 139",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA SEGUROLA 1574",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA SEGUROLA 1574",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA. EVA PERON NRO.5862",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA. OLIVERA 1263",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA. RIVADAVIA 7363",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA.DIRECTORIO 2601",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA.EVA PERON NRO.5338",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA.EVA PERON NRO.5338",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVENIDA EVA PERON 4849",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "CARHUE 99",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Chilavert  6364",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "J.BONIFACIO 2895 (ENTREG.X S.PEDR",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "JOSE BONIFACIO 2895",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "JOSE BONIFACIO 2895",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "JOSE PEDRO VARELA 4720",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "MARIANO ACOSTA 1449",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "NAZCA 1341",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "PIEDRABUENA 6038",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "TINOGASTA 3702",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV  DIRECTORIO 4709",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Av Boedo 937",
      "zona": "CABA ",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "AV COBO 1189",
      "zona": "CABA ",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "AV DIAZ VELEZ 5101",
      "zona": "CABA ",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "AV MONTES DE OCA 1229",
      "zona": "CABA ",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "AVDA LAFUENTE 2899",
      "zona": "CABA ",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "AVDA.CASEROS 2657 P.B.UF2",
      "zona": "CABA ",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "ESPINOSA 1634",
      "zona": "CABA ",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "FLORIDA 52",
      "zona": "CABA ",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "MURGUIONDO 4064/68",
      "zona": "CABA ",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "RIVADAVIA 9626",
      "zona": "CABA ",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "AV OCAMPO ESQUINA AHUMADA Y BARRO",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SALTA",
      "zona": "SALTA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "AV OCAMPO ESQUINA AHUMADA Y BARRO",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "BELEN",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CATAMARCA",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CHUMBICHA CATAM",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "S F DEL VALLE D",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "S.F.V. DE CATAM",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "S.F.V. De Catam",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAB FERNANDO DE",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN F DEL V DE CATAMARCA",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN F. DEL VALL",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN FDO DEL VAL",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN FERNANDO DE",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "TINOGASTA",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VALLE MARIA",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VALLE VIEJO",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VILLA MERCEDES",
      "zona": "CATA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "ADELIA MARIA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "ADELIA MARIA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "ALTA GRACIA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "AUSONIA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "BARRIO RAWSON",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "BELL VILLE",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "BENITO JUAREZ",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "BUENA NUEVA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "CAPILLA DEL MON",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "CARLOS PAZ",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "CAVANAGH",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "CHAMICAL",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "COL. CAROYA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "COLONIA CAROYA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "Cordoba",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "CORDOBA CAPITAL",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "CORDOBA( BRICKS",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "CORRAL DE BUSTO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "COSQUIN",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "CRUZ DEL EJE",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "DEAN FUNES",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "EMBALSE",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "FREYRE",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "GENERAL CABRERA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "H. YRIGOYEN 115",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "HERNANDO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "Icho Cruz",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "JAMES CRAIK",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "JESUS MARIA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "JUSTINIANO POSS",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "LA CALERA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "LA CARLOTA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "LA CUMBRE",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "LA FALDA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "LAGUNA LARGA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "LAS ACEQUIAS",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "LAS VARILLAS",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "LEONES",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "LIBERTAD 279",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "LIBERTAD 559",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "LOS REARTES",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "LUQUE",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "MARCOS JUAREZ",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "MARULL",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "MORRISON",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "O´HIGGINS N° 5591",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "OBISPO TREJO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "ONCATIVO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "PUEBLO ESTHER",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "QUILINO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "RIO CEBALLOS",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "RIO CUARTO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "Rio Primero",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "RIO SEGUNDO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "RIO TERCERO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "RUTA 38 KM 82 S/N",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "SALSIPUEDES",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "SAMPACHO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "SAMPACHO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "SAN FRANCISCO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "SAN GUILLERMO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "SAN JUAN",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "SAN LUIS",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "SANTA ROSA DE C",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "UNQUILLO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "URUGUAY 498",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "VICENTE AGUERO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "VICU¥A MACKENNA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "VILLA ALLENDE",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "VILLA CARLOS PA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "VILLA CARLOS PAZ",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "VILLA DEL LAGO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "VILLA DEL TOTOR",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "VILLA DEL TOTOR",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "VILLA MARIA",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "VILLA SANTA CRU",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "CORONEL DU GRATY",
      "zona": "CTES",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "CORRIENTES",
      "zona": "CTES",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "FONTANA",
      "zona": "CTES",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "MBURUCUYA",
      "zona": "CTES",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "RESISTENCIA",
      "zona": "CTES",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "COOPERATIVA Y AV.P.LLORENA MZ.43",
      "zona": "CUYO",
      "apm": "SABRINA SARMIENTO"
    },
    {
      "localidad": "Godoy Cruz",
      "zona": "CUYO",
      "apm": "SABRINA SARMIENTO"
    },
    {
      "localidad": "Bahia Blanca",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "BAHIA BLANCA",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "BALNEARIO CLARO",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "BARDA DEL MEDIO",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "CARMEN DE PATAG",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "CARMEN DE PATAGONES",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "CNEL PRINGLES",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "COMANDANTE LUIS",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "DIEGO DE ALVEAR",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "EMBAJADOR MARTI",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "ESQUEL",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "GENERAL ACHA",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "GENERAL CONESA",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "GENERAL CONESA",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "GUATRACHE",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "HILARIO ASCASUB",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "HILARIO ASCASUBI",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "Ingeniero White",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "LAS GRUTAS",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "LUIS PIEDRA BUE",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "MACACHIN",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "MAYOR BURATOVIC",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "MONTE HERMOSO",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "PEDRO LURO",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "PUEBLO SANTA MA",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "PUERTO DESEADO",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "PUERTO MADRYN",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "PUNTA ALTA",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "QUEQUEN",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "REALICO",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "RIO COLORADO",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "S.DE LA VENTANA",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "SALLIQUELO",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "SAN ANTONIO OES",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "SAN ANTONIO OESTE",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "SANTA ROSA",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "SANTA ROSA DE R",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "Sierra De La Ve",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "SIERRA GRANDE",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "TORNQUIST",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "TRELEW",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "TRES ARROYOS",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "VALCHETA",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "VIEDMA",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "VILLA ARCADIA",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "VILLA VENTANA",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "VILLALONGA",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "AV  CABILDO 2540",
      "zona": "E-COM",
      "apm": "E-COMMERCE"
    },
    {
      "localidad": "GREG.DE LA FERR",
      "zona": "E-COM",
      "apm": "E-COMMERCE"
    },
    {
      "localidad": "GREGORIO DE LAF",
      "zona": "E-COM",
      "apm": "E-COMMERCE"
    },
    {
      "localidad": "Gregorio Laferr",
      "zona": "E-COM",
      "apm": "E-COMMERCE"
    },
    {
      "localidad": "LAFERRERE",
      "zona": "E-COM",
      "apm": "E-COMMERCE"
    },
    {
      "localidad": "AV CABILDO 2540",
      "zona": "E-COMM",
      "apm": "E-COMMERCE"
    },
    {
      "localidad": "25 DE MAYO 30",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "25 DE MAYO 302",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "BASAVILBASO",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "BELGRANO 295",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "C. DEL URUGUAY",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "COLON",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "CONCEPCION DEL",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "CONCEPCION DEL URUGUAY",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "CONCORDIA",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "CONCP DEL URUGU",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "CRESPO",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "CRESPO, ENTRE R",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "DIAMANTE",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "DR DALMACIO VELEZ SARSFIELD 248",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "ENTRE RIOS 651",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "ESTRADA 1652",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "EVA PERON 362 (ESQ. MITRE)",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "GENERAL RAMIREZ",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "GOBERNADOR CRES",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "GRAL PAZ 455",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "GRAL SAN MARTIN 2983",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "GUALEGUAYCHU",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "GUALEGUAYCHU 446",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "LARROQUE",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "LIB GRAL SAN MA",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "LOS CHARRUAS",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "NOGOYA",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "RAMIREZ",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "SAN MARTIN 2983",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "SAN MARTIN GRAL JOSE DE 2983",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "SAN SALVADOR",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "SARMIENTO 679",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "SEGUI",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "VILLA ELISA",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "VILLA LIBERTADO",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "VILLAGUAY",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "LDOR GRAL SAN MARTIN",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "ESPERANZA",
      "zona": "SANTA FE",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "SAN CARLOS CENT",
      "zona": "SANTA FE",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "SAN CARLOS CENTRO",
      "zona": "SANTA FE",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "SAN CARLOS NORT",
      "zona": "SANTA FE",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "SAN CARLOS SUD",
      "zona": "SANTA FE",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "SANTA FE",
      "zona": "SANTA FE",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "SANTA FE (002)",
      "zona": "SANTA FE",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "SANTO TOME",
      "zona": "SANTA FE",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "PARANA",
      "zona": "ER PARANA",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "SAN MARTIN GRAL JOSE DE 2983",
      "zona": "ER PARANA",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "ADROGUE",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "ALEJANDRO KORN",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "ALMIRANTE BROWN",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "AV  GENOVA 4211",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "AVDA HIPOLITO YRIGOYEN 13301",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "AVELLANEDA",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "BANFIELD",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "BANFIELD LOMAS",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "BERAZATEGUI",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "BERAZATEGUI OES",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "BERNAL",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "BERNAL ESTE",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "BERNAL OESTE",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "BERNAL QUILMES",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "BOSQUES",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "BURZACO",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "CALLE 7 NRO 1249",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "CAÑUELAS",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "CARLOS SPEGAZZI",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "CHOELE CHOEL 465",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "CLAYPOLE",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "DON BOSCO-QUILM",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "EL JAGUEL",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "ENSENADA",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "ESTEBAN ECHEVER",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "EZEIZA",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "EZPELETA",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "F VARELA",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "FABIAN ONSARI 1598",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "FCIO VARELA",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "FLORENCIO VAREL",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "GENOVA 4211",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "GERLI",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "GERLI",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "GERLI",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "GUERNICA",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "HUDSON - BERAZA",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "HUDSON BERAZATE",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "JOSE LEON SUAREZ",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LA MERCED 214",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LANUS",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LANUS ESTE",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LANUS GERLI EST",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LANUS OESTE",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LANUS VALENTIN",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LAS FLORES 1600",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LAVALLOL",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LINIERS Y S. MARCOS S/N ( CALLE 1",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LOMAS DE ZAMORA",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LONGCHAMPS",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LOS HORNOS",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LUIS GUILLON",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "Melchor Romero",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "MONTE GRANDE",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "MUNIZ",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "MUÑIZ",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "NUESTRA SRA DE LA MERCED 380",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "Quilmes",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "QUILMES ESTE",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "QUILMES OESTE",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "REMEDIOS DE ESC",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "RP PROF DR MARIANO CASTEX 1621",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "SAN FCO.SOLANO",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "SARANDI",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "SOLANO",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "TEMPERLEY",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "TEMPERLEY",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "VALENTIN ALSINA",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "WILDE",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "ZAPILA 1057",
      "zona": "SUR",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "L N ALEM 2654",
      "zona": "USHU",
      "apm": "USHUAIA"
    },
    {
      "localidad": "RIO GRANDE",
      "zona": "USHU",
      "apm": "USHUAIA"
    },
    {
      "localidad": "TOLHUIN",
      "zona": "USHU",
      "apm": "USHUAIA"
    },
    {
      "localidad": "USHUAIA",
      "zona": "USHU",
      "apm": "USHUAIA"
    },
    {
      "localidad": "AZUL",
      "zona": "NORTE GIRA",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "OLAVARRIA",
      "zona": "NORTE GIRA ",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "TANDIL",
      "zona": "NORTE GIRA",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "BIGAND",
      "zona": "GR JS",
      "apm": "GR JS"
    },
    {
      "localidad": "CASILDA",
      "zona": "GR JS",
      "apm": "GR JS"
    },
    {
      "localidad": "FIRMAT",
      "zona": "GR JS",
      "apm": "GR JS"
    },
    {
      "localidad": "VENADO TUERTO",
      "zona": "GR JS",
      "apm": "GR JS"
    },
    {
      "localidad": "JUJUY",
      "zona": "JUJUY",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "PERICO",
      "zona": "JUJUY",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "PERICO - JUJUY",
      "zona": "JUJUY",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "S S DE JUJUY",
      "zona": "JUJUY",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "S.S. DE JUJUY",
      "zona": "JUJUY",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SALVADOR MAZZA",
      "zona": "JUJUY",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN PEDRO DE JU",
      "zona": "JUJUY",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN S. JUJUY",
      "zona": "JUJUY",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN SALVADOR DE",
      "zona": "JUJUY",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN SALVADOR DE JUJUY",
      "zona": "JUJUY",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "ALSINA 265",
      "zona": "JUNIN",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "B DE MIGUEL Y ALMAFUERTE",
      "zona": "JUNIN",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "JUNIN",
      "zona": "JUNIN",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "9 DE JULIO",
      "zona": "JUNIN GIRA",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "ALBERTI",
      "zona": "JUNIN GIRA",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "AMERICA",
      "zona": "JUNIN GIRA",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "ARENAZA",
      "zona": "JUNIN GIRA",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "ARENAZA",
      "zona": "JUNIN GIRA",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "BRAGADO",
      "zona": "JUNIN GIRA",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "CHACABUCO",
      "zona": "JUNIN GIRA",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "CHIVILCOY",
      "zona": "JUNIN GIRA",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "LINCOLN",
      "zona": "JUNIN GIRA",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "NUEVE DE JULIO",
      "zona": "JUNIN GIRA",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "PEHUAJO",
      "zona": "JUNIN GIRA",
      "apm": "THIAGO GARAVAGLIA"
    },
    {
      "localidad": "CHILECITO",
      "zona": "LA RIOJA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CHILECITO- LA R",
      "zona": "LA RIOJA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LA RIOJA",
      "zona": "LA RIOJA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SARMIENTO 679  ESQ PERON",
      "zona": "LABBI",
      "apm": "LABBI"
    },
    {
      "localidad": "AV GDOR MARCELINO UGARTE 1728",
      "zona": "LELOIR",
      "apm": "LELOIR"
    },
    {
      "localidad": "UGARTE 1728",
      "zona": "LELOIR",
      "apm": "LELOIR"
    },
    {
      "localidad": "BERISSO",
      "zona": "LT",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "CITY BELL",
      "zona": "LT",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "GONNET",
      "zona": "LT",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "GONNET-LA PLATA",
      "zona": "LT",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "LA PLATA",
      "zona": "LT",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "M.B. GONNET",
      "zona": "LT",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "MANUEL B GONNET",
      "zona": "LT",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "TOLOSA - LA PLA",
      "zona": "LT",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "PILAR",
      "zona": "MARINA",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "DERQUI - PILAR",
      "zona": "MARINA",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "FATIMA - PILAR",
      "zona": "MARINA",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "MAR DEL PLATA",
      "zona": "MDP",
      "apm": "MAR DEL PLATA"
    },
    {
      "localidad": "ALLEN",
      "zona": "MG",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "Cipolletti",
      "zona": "MG",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "CIPOLLETTI",
      "zona": "MG",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "NEUQUEN",
      "zona": "MG",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "NEUQUEN",
      "zona": "MG",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "PLOTTIER",
      "zona": "MG",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "SENILLOSA",
      "zona": "MG",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "AMEGHINO",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "ANDACOLLO",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "ANELO",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "APOLINARIO SARA",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "ARRAYANES Y LOS COIHUES",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "AV ALTE G BROWN 642",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "AV GRAL PAZ 50",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "AV GRAL PAZ 50",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "AV ING CESAR CIPOLLETTI 162",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "AV ING CESAR CIPOLLETTI 162",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "AV. CARCANO 610",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "BARILOCHE",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "BARILOCHE",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "CALETA OLIVIA",
      "zona": "MG COMODORO",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "CATRIEL",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "CENTENARIO",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "CHOELE CHOEL",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "CHOS MALAL",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "CINCO SALTOS",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "COMODORO RIVADA",
      "zona": "MG COMODORO",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "COMODORO RIVADA",
      "zona": "MG COMODORO",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "COMODORO RIVADAVIA",
      "zona": "MG COMODORO",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "COMORODO RIVADA",
      "zona": "MG COMODORO",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "CORONEL DORREGO",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "CUTRAL CO",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "CUTRAL-CO",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "DINA HUAPI",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "DR FEDERICO LELOIR 465",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "EDUARDO ELORDI",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "EL BOLSON",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "EL MAITEN",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "GENERAL BELGRAN",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "GENERAL MOSCONI",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "GENERAL ROCA",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "HUANGUELEN",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "JUAN COUSTE",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "JUNIN DE LOS AN",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "JUNIN DE LOS AN",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "LAMARQUE",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "Las Perlas",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "LONCOPUE",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "MELINCUE",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "PICUN LEUFU",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "PLAZA HUINCUL",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "R.DE LOS SAUCES",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "RADA TILLY",
      "zona": "MG COMODORO",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "RANCUL",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "RIO GALLEGOS",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "RIO NEGRO",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "RIVADAVIA 1598",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "S.M.DE LOS ANDE",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "S.M.DE LOS ANDE",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "S.M.DE LOS ANDE",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "San Carlos De B",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "SAN MARTIN DE L",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "VILLA LA ANGOST",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "VILLA MANZANO",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "VILLA PEHUENIA",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "VILLA REGINA",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "ZAPALA",
      "zona": "MG GIRA",
      "apm": "NEUQUEN"
    },
    {
      "localidad": "Mendoza",
      "zona": "MZA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "9 DE JULIO 459",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "9 DE JULIO 459",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "9 DE JULIO 459",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "ALBERDI JUAN BAUTISTA 31",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "BELLA VISTA",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "Calle: AV. SAN MARTIN  S/N",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "CAMPO LARGO",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "CHARATA",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "COMANDANTE FONT",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "CORONEL DU GRAT",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "CORZUELA",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "CURUZU CUATIA",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "DR MARIANO MORENO 601",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "EL SOBERBIO",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "ELDORADO",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "FLORENCIA",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "FORMOSA",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "GENERAL PINEDO",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "GENERAL VELAZCO 1305",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "GOYA",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "GRAL JUAN F VELAZCO 1798",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "GRAL SAN MARTIN 386",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "GRAL. PINEDO",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "HERMOSO CAMPO",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "ING GUILLERMO N",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "JARDIN AMERICA",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "Las Breas",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "LAS BRENAS",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "LAS BREÑAS",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "MACHAGAY",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "MAYOR VILLAFAÑE",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "MONTECARLO",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "OBERA",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "PAMPA DEL INFIE",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "Paso De Los Lib",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "Pcia. Roque Sae",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "PIRANE",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "POSADAS",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "PRESIDENCIA ROQ",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "PUERTO RICO",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "Quitilipi",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "RIVADAVIA 955",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "ROLDAN 400",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "RUTA NAC.43 KM 1.78",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "SAENZ PE%A",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "Saenz Pea",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "SAENZ PEÑA",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "SAEZ PEÑA",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "SAN BERNARDO DE",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "SAN MARTIN 155",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "SAN VICENTE",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "SANTA SYLVINA",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "VILLA ANGELA",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "VILLA BERTHET",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "SANTA SYLVINA-",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "Alem",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "Palo Santo",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "Palo Santo",
      "zona": "NEA",
      "apm": "MARIANO AGAZZANNI"
    },
    {
      "localidad": "Acassuso",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "AV  DE LOS INCAS 4960",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "AV CRISOLOGO LARRALDE 3195",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "AVDA. CABILDO 4633",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "AVDA. MAIPU 2702",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "BECCAR",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "BELEN DE ESCOBA",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "BELEN DE ESCOBAR",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "BENAVIDEZ",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "Benavidez",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "BOULOGNE",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "CARAPACHAY",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "CNEL BRANDSEN 2111",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "COLECTORA ESTE RUTA 9 KM. 55,5 0",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "COLECTORA OESTE 770",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "DEL VISO",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "DON TORCUATO",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "EL TALAR",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "EL TALAR DE PAC",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "ESCOBAR",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "FLORIDA",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "GENERAL PACHECO",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "GRAL PACHECO",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "GRAL.PACHECO",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "ING MASCHWITZ",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "ING. MASCHWITZ",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "INGENIERO MASCH",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "LA LONJA",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "LA LONJA - PILA",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "LOS POLVORINES",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "MARTINEZ",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "MARTINEZ-ACASSUSO",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "MUNRO",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "NORDELTA",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "NORDELTA - TIGR",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "NORDELTA - TIGRE",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "NORDELTA TIGRE",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "NORDELTA-TIGRE",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "OLIVOS",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "Pablo Nogues",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "PACHECO",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "R.DE LA PATRIA Y ACASUSSO",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "RUTA 25 1805 Y MANSILLA",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "RUTA 25 NRO.1171",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "S.FERN-VIRREYES",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "SAN ANDRES",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "SAN FERNANDO",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "SAN ISIDRO",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "SAN JUAN 2767",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "SAN JUAN 2767",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "TALAR",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "TALAR DE PACHEC",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "TIGRE",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "TRONCOS DEL TAL",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "VICENTE LOPEZ",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "VILLA ADELINA",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "VILLA CELINA",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "Villa Marteli",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "VILLA MARTELLI",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "VIRREYES",
      "zona": "NORTE",
      "apm": "GBA.NORTE"
    },
    {
      "localidad": "18 DE DICIEMBRE DE 1856 2053",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV ALVAREZ JONTE 4683/85",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Av Federico Russo 4996",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AVDA PTE PERON 1874",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "C JARDIN PALOMA",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "CASEROS",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "CASTELAR",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "CASTELAR - MORO",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "CIUDAD EVITA-LA",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Ciudad Jardin",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "CIUDAD JARDIN D",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "CIUDADELA",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "EL PALOMAR",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "FCO ALVAREZ",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "FRANCISCO ALVAR",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "G. DE LAFERRERE",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "GENERAL SAN MAR",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "GENERAL SAN MARTIN",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "GRAL SAN MARTIN",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Gregorio de la",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "HAEDO",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "HURLINGHAM",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "ISIDRO CASANOVA",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "ITUZAINGO",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "JAUREGUI - LUJA",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "JOSE C PAZ",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "JOSE C. PAZ",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "JOSE C.PAZ",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "JOSE CLEMENTE P",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "JOSE L.SUAREZ",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "JOSE LEON SUARE",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "LA MATANZA",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "LA TABLADA-LA M",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "LAS CATONAS-MOR",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "LIBERTAD",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "LIBERTAD - MERL",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "LIBERTAD-MERLO",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "LOMA HERMOSA",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "MALVINAS 2359",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "MALVINAS 2359",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "MERLO",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "MERLO NORTE",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "MORENO",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "MORENOÂ ",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "MORON",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "MU%IZ - SAN MIG",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "MUÑIZ - SAN MIG",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "PALOMAR",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "PARQUE SAN MART",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "PASO DEL REY",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "R MEJIA",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Ramos Mejia",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "RAMOS MEJIAS",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "SAN A.DE PADUA",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "SAN ANDRES DE G",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "SAN ANTONIO DE",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "San Justo",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "SAN JUSTO",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "SAN MARTIN",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "SAN MIGUEL",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "SAN MIGUEL-MUNIZ",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "TABLADA",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "TAPIALES",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "TESEI",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "V BOSCH",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "V. BALLESTER",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Villa  Tesei",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "VILLA BALLESTER",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "VILLA BOSCH",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "VILLA LUZURIAGA",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "VILLA LYNCH",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "VILLA MADERO",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "VILLA SANTOS TE",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "VILLA SARMIENTO",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "VILLA TESEI",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "VILLA TESSEI",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "CANNING",
      "zona": "OESTE GIRA",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "GENERAL RODRIGU",
      "zona": "OESTE GIRA",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "GENERAL RODRIGUEZ",
      "zona": "OESTE GIRA",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "GRAL RODRIGUEZ",
      "zona": "OESTE GIRA",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "GRAL. RODRIGUEZ",
      "zona": "OESTE GIRA",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "LA REJA",
      "zona": "OESTE GIRA",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "LUJAN",
      "zona": "OESTE GIRA",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "LUJANÂ ",
      "zona": "OESTE GIRA",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "Pte.Peron 246",
      "zona": "OESTE GIRA",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "PERGAMINO",
      "zona": "PERGAMINO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "PERGAMINO (002)",
      "zona": "PERGAMINO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "ARROYITO",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "ARROYITO",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "AVDA CARLOS PELLEGRINI 1195",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "BARRANCAS",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "DIAZ",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "FUNES",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "PTO.GRAL.SAN MA",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "ROLDAN",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "ROSARIO",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "SAN NICOLAS",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "SAN NICOLAS DE",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "SAN NICOLAS DE LOS ARROYO",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "V.CONSTITUCION",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "VILLA GDOR.GALV",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "VILLA GOBERNADO",
      "zona": "ROSARIO",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "CAÃ‘ADA DE GOMEZ",
      "zona": "ROSII",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "CARCARAÃ‘A",
      "zona": "ROSII",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "DESPEÃ‘ADEROS",
      "zona": "ROSII",
      "apm": "FABRICIO COMOLLI"
    },
    {
      "localidad": "ALCORTA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "ALDEA BRASILERA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "AREQUITO",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "ARRECIFES",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "BELGRANO 845",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "BOMBAL",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "BUSTINZA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "Calchaqui",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CANDIOTI",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CAPITAN BERMUDE",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CARCARANA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CARCARAÑA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CENTENO",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CERES",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CHABAS",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CORONDA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CORONEL ARNOLD",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "EMILIA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "EMPALME VILLA C",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "FIGHIERA/ALVEAR",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "FRANCK",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "FRAY LUIS BELTR",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "GALVEZ",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "GESSLER",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "GRANADERO BAIGO",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "HELVECIA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "HERSILIA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "HUGHES",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "HUMBERTO PRIMER",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "HUMBOLDT",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LAGUNA PAIVA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LAS ROSAS",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LAS TOSCAS",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LLAMBI CAMPBELL",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LOS MOLINOS",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "MARGARITA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "MITRE 1124",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "MONTE MAIZ",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "MONTES DE OCA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "OLIVEROS",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "OLIVEROS",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "Peyrano",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "PINTO - VILLA G",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "PROGRESO",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "PTE D F SARMIENTO 598",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "PUEBLO ALCORTA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "PUEBLO AREQUITO",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "RAFAELA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "RECREO",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN CRISTOBAL",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN FRANCISCO S",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN GENARO NORT",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN JERONIMO NO",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN JERONIMO SU",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN JOSE-RAF.CA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN LORENZO",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN PEDRO",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SANFORD",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SANTA CLARA DE",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SANTA ELENA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAUCE VIEJO",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SOLDINI",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SUNCHALES",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "TEODELINA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "TOSTADO",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "V.MUGUETA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VEDIA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VERA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VICTORIA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VIDELA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VILLA CANAS",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VILLA CONSTITUC",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VILLA ELOISA",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VILLA GDOR. GAL",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VILLA MUGUET",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VILLA RAMALLO",
      "zona": "S.FE GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "ANDALGALA",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "APOLINARIO SARAVIA 20",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "AV. ENTRE RIOS 850",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "BANDA RIO SALI",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "CAFAYATE",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "EMBARCACIÃ“N",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "EMBARCACION",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "GENERAL GUEMES",
      "zona": "SALTA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "GENERAL GUEMES-",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "GRAL GUEMES",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "GRAL GUEMES - S",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "GRAL SAN MARTIN 329",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "GUEMES",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "METAN",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "ORAN",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "ORAN- SALTA",
      "zona": "SALTA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SALTA - CAPITAL",
      "zona": "SALTA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SALTA CAPITAL",
      "zona": "SALTA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN JOSE DE MET",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "TARTAGAL",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "TRANCAS",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "VAQUEROS- SALTA",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "SAN RAMON DE LA NVA ORAN",
      "zona": "SALTA",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "SGO DEL ESTERO",
      "zona": "SDE",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SANTIAGO DEL ES",
      "zona": "SDE",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SANTIAGO DEL ESTERO",
      "zona": "SDE",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SANTIAGO DELEST",
      "zona": "SDE",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "Campana",
      "zona": "TG GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LIMA",
      "zona": "TG GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LIMA - ZARATE.",
      "zona": "TG GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LOS CARDALES",
      "zona": "TG GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "ZARATE",
      "zona": "TG GIRA",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "MONTEROS- TUCUM",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "AGUILARES",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "AGUILARES-SMT",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "ALEJO LEDESMA",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "AV. SARMIENTO ESQ. VIEYTES",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "B° EL TRIBUNO -",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "B° PARQUE GRAL",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "BANDA DEL RIO S",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CAPITAL",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CAPITAL",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CAPITAL",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CAPITAL",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "COLONIA AVELLAN",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CONCEPCION",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "CONCEPCIÓN",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "EL CHAMICAL",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "FAMAILLA",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "FAMAILLA - TUCU",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LA BANDA",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LA COCHA",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LA LAJITAS",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LA LAJITAS",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LA MERCED",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LA MERCED",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LULES",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "MONTEROS",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "MONTEROS - TUCU",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "MONTEROS-TUCUMA",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "MORTEROS",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "RIO HONDO",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "RIO SECO",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "S M DE TUCUMAN",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN LORENZO-SAL",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN M. DE TUCUM",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN MARTIN 1538",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN MIGUEL DE T",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SAN MIGUEL DE TUCUMAN",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "SANTA  MARIA -",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "TAFI DEL VALLE",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "TAFI IEJO- TUCU",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "TAFI VIEJO",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "TAFI VIEJO- TUC",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "TERMAS DE RIO H",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "TERMAS DE RIO HONDO",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VILLA DOLORES",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VILLA DOLORES (",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "VILLA REBECA- S",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "YERBA BUENA",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "YERBA BUENA",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "YERBA BUENA- TU",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LULES- TUCUMAN",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "POZO DEL TIGRE",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "1º DE MAYO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "25 DE MAYO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "25 DE MAYO 579",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "25 DE MAYO 993",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "25 DE MAYO NRO. 222",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "30 DE AGOSTO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "9 DE ABRIL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "A¥ELO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AÃ‘ATUYA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Aatuya",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ABASTO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ABASTO - LA PLA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ACCESO NORTE AV. 12 DE OCTUBRE KM",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ACEBAL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AGUARAY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AGUSTIN ROCA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AIMOGASTA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALBA POSSE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALBERDI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALCIRA ESTACION",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALCIRA GIGENA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALCORTA(D.CONST",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALDEA MARIA LUI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALDEA SAN ANTON",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALDEA VALLE MAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALDERETES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALDO BONZI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALICIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALMAFUERTE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALPACHIRI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALTOS DE SAN LO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALUMINE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALVAREZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ALVEAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AMAICHA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Amboy",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AMENABAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ANGEL ECHEVERRY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ANGEL ETCHEVERR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AÑATUYA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AÑELO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "APOSTOLES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARANGUREN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARGÜELLO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARIAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARISTOBULO DEL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARMSTRONG",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARRIBE#OS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARRIBE¥OS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARRIBEÑOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARROYO CABRAL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARROYO DULCE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARROYO SECO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARRUFO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARTEAGA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARTIGAS 117",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARTURO SEGUI-LA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ASCENCION",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ASCENSION",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AUDA. OLIVERA 1263",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  COBO 1894",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  CORRIENTES 4617",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  CORRIENTES 5541",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  CORRIENTES 5901",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  DE LOS CONSTITUYENTES 3833",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  DE LOS CONSTITUYENTES 6020",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  DE LOS CONSTITUYENTES 6117",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  DE MAYO 675",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  DEL BARCO CENTENERA 3111",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  GAONA 2308",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  LA PLATA 1234",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  LOPE DE VEGA 1690",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  LOPE DE VEGA 3537",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  NAZCA 1982",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  NAZCA 3714",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  SAENZ 1187",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  SAENZ 895",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  SAENZ 928",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV  SAN MARTIN 68",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV 9 DE JULIO 2",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV ALTE G BROWN 1361",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV ANTONIO ALVAREZ JONTE 4873",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV AVELLANEDA 3724",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 11552",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV BERNARDINO RIVADAVIA 9626",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV BOEDO 937",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Av Constituyentes 6117",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Av Constituyentes 6163",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV CORDOBA 4601",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV CORRIENTES 4647",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV CORRIENTES 5258",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Av De La Riestra 5758",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Av de los Corrales6801",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV DE LOS INMIGRANTES S/N 00",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV DR NICOLAS AVELLANEDA 3724",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV DR NICOLAS AVELLANEDA 896",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV DR RICARDO BALBIN 3057",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV EMILIO CASTRO 7649",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV EVA PERON 4849",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Av F.F. de Cruz 6290",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV FERNANDEZ DE LA CRUZ 3342",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV GDOR EMILIO CASTRO 7649",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV JOSE M MORENO 245",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV JUAN GARCIA DEL RIO 4097",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV LOPE DE VEGA 1690",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV LOPE DE VEGA 2599",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV MARIANO BOEDO 490",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV MARIANO BOEDO 937",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV MARIANO BOEDO 953",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV MONTES DE OCA 590",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV NAZCA 3001",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV NORBERTO DE LA RIESTRA 5758",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV PTE H YRIGOYEN 1180",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV RIVADAVIA 11356",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV RIVADAVIA 11420",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV RIVADAVIA 4451/53/55",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV SAENZ 1181",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV SAENZ 1187",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV SAENZ 928",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV SAN JUAN 3335",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV SAN MARTIN 2159",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV SCALABRINI ORTIZ 1383",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV SCALABRINI ORTIZ 361",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV SEBASTIAN ELCANO 3364",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV. CASEROS 3399",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Av. Corrientes N 4025",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV. CROVARA 4389",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV. DE LA RIESTRA 5758",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV. DE LOS RIOS 1048 ESQ. JUAN J",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Av. Emilio Castro 6699",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Av. Emilio Castro 7637",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Av. La Plata 2501",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV. RIVADAVIA 8749",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Av. Rivadavia 9123",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV. SAENZ 928",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Av. San Martin 1216",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV.DIAZ VELEZ NRO.5101",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV.DIAZ VELEZ NRO.5101",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV.DIAZ VELEZ NRO.5101",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV.DIAZ VELEZ NRO.5101",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Av.O'Brien S/N",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV.REG.PATRICIOS NRO.935",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV.SAN JUAN 3162",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA 9 DE JULIO 4787",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA BELGRANO 3002/04",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA COBO 1894",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA CONSTITUYENTES 6020",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA EVA PERON 4256",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA F.F.DE LA CRUZ 3342",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA F.F.DE LA CRUZ 3342",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA LA PLATA 2501",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA LA PLATA 567",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA LA PLATA 586",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA LIBERTADORES 366",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA RIVADAVIA 11552",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA. LOPE DE VEGA 3102",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA. LOPE DE VEGA 3249",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA. LOS TILOS 185",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA.ALVAREZ JONTE 3721",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA.ALVAREZ JONTE 3721",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA.JUAN B.JUSTO 9301",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA.SAN JUAN NRO.2833",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVDA.SANTANDER 5101/09",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVENIDA BOEDO NRO.937",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVENIDA SAENZ 1187",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AVIA TERAI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AYACUCHO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Azopardo 350 Piso 3",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "B.ALAS - CIUDAD",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "B.SUR MER E/ALB S/N",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BÂ° SAN PEDRITO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BAEZ 400 Y AV GRAL INDALESIO CHEN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BAHIA SAN BLAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BAIGORRITA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BALCARCE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BALNEARIO LAS G",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BANDERA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BARADERO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Barranqueras",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BARRIO LA CALER",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BARTOLOME BAVIO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BASAIL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BAT DE RINCON 96",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BATALLA DE SALTA 310",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BAUNESS 2600",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BELGRANO 202",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BELGRANO 98",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BELTRAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BERABEVU",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BERNARDO DE IRI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BERNASCONI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BERON DE ASTRAD",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BERROTARAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BERTAZZONI 5",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BIALET MASSE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BILLINGHURST",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BOEDO 2054 PB",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BOEDO 783",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BOLIVAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BOLIVAR 1001",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BONIFACIO (GUAM",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BORDENAVE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BOUQUET",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BOVRIL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BRANDSEN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BRASIL 1128",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BRASIL 800",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Brinkman",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BRINKMANN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BUNGE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BUTA RANQUIL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BVRD DR RODOLFO MOYANO 233",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BVRD GRAL JOSE G ARTIGAS 853",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CA¥ADA ROSQUIN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CA¥ADON SECO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Caada Rosquin",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CABILDO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CACHI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAFFERATA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CALCHIN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CALLE 49 4972",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Calle: GUALEGUAYCHU Nro.: 802",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAMILO ALDAO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAMPO DE MAYO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAMPO GRANDE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAMPO QUIJANO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAMPO VIERA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CANADA DE GOMEZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CANALS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Candelaria",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CANUELAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAÑADA DE GOMEZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAÑADA DEL UCLE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAÑADA ROSQUIN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAÑADA SECA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAPAYAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAPILLA DEL SE%",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAPILLA DEL SEÃ‘",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAPILLA DEL SEÑ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Capilla Del Seo",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Capital Federal",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAPITAN SARMIEN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CARHUE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CARILO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CARLOS CALVO 3601",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CARLOS CASARES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CARLOS PELLEGRI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Carlos Tejedor",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CARMEN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CARMEN DE ARECO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CARRERAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CASEROS 2980",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CASEROS AV. 3399",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAUCETE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CAYASTA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CDAD J.EL LIBER",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CERRILLOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CERRITO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CERRO AZUL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Cervantes",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHAÃ‘AR LADEADO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHACABUCO 242",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHACABUCO S/N S/N",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHAJARI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHAÑAR LADEADO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHASCOMUS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHEPES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Chichinales",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHICHINALES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHICOANA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHILABERT 6364",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHILAVERT 6577",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHIMPAY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHOVET",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHUMBICHA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CHURRUCA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CIRCUNV. Y SUCRE 2359",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CIUDAD EVITA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CIUDAD MADERO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CLAROMECO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CLASON",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CLORINDA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CLUCELLAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CNEL P MURGUIONDO 4064",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CNEL P MURGUIONDO 4172",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CNEL. GRANADA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CNL. PRINGLES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COLASTINE NORTE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COLAZO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COLOMBRES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COLONIA ALDAO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COLONIA ARISTOB",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COLONIA AURORA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COLONIA AYUI GR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COLONIA BELGRAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COLONIA BOMBAL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COLONIA MARINA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COLONIA SANTA R",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COLONIA TIROLES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COMANDANTE ANDR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CONCARAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CONCEPCION DE L",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CONCEPCIÓN DEL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "COQUIMBITO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CORDOBA Y BELGRANO 0 - BARRIO : C",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CORONEL BOGADO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CORONEL BRANDSE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CORONEL MOLDES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CORONEL PRINGLE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CORONEL SUAREZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CORRALITO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CORREA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CORTINES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CORTINEZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CRUCESITA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CRUZ ALTA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CTE.CORDERO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CUARTEL IV, ZAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CUZCO 125",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DAIREAUX",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DE LAS NACIONES Y CAP.GIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DEL VALLE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Desamparados",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DESPENADEROS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DIQUE LUJAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DIQUE LUJAN - T",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Dolavon",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Dolores",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DOMINGO F SARMIENTO 698",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DOMINGO FUNES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DON BOSCO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DORREGO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DOS DE MAYO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DR A ALSINA 1801",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DR MARIANO MORENO 900",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DR PEDRO GOENAGA 592",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "DUDIGNAC",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ECUADOR 864",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EDISON 460",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EDUARDO CASTEX",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL ALCAZAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL CALAFATE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL CARMEN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL CARRIL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL CHALTEN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL CHOCON",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL COLORADO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "El Dorado",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL FORTIN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL GALPON",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL HOYO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL LIBERTADOR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL MANANTIAL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL PERDIDO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL SOCORRO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL TIO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EL TREBOL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ELISA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ELISA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ELORTONDO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EMBALSE (CBA)",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EMBARCACIÃƒâ€œN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EMILIO CASTRO 6699",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EMILIO V. BUNGE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EMP.VILLA CONST",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EMPEDRADO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ENSENADA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EPUYEN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ESCOBAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ESPARTILLAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ESQUINA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ESTACION SOURDE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ESTANISLAO DEL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ETRURIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Eugenio Bustos",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EUSEBIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EUSEBIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "EZPELETA OESTE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "F DE LAS CARRERAS 126",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FAMATINA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FATIMA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FATIMA ESTACION",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FCO. ALVAREZ-MO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FEDERACION",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FEDERAL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FELICIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Fernandez Oro",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FERRE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Fiambala",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FORTIN TIBURCIO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FRAILE PINTADO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FRANCISCO DE AGUIRRE 95",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FRANCISCO DE VIEDMA 6901",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FRAY LUIS BERTR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FRAY STA MARIA DE ORO 2927",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Frias",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "FUENTES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "G.E.HUDSON",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "G.Laferrere",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GAIMAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GANCEDO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GARIN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GDOR ING V VIRA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL ALVEAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL ARENALE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "General Arenale",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL BALDISS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL CAMPOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL CERRI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "General Daniel",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL DEHEZA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL FERNAND",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "General Galarza",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "General Gelly",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL GUTIERR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL JOSE DE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL LAGOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL LAS HER",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL LEVALLE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL O´BRIEN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL PICO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GENERAL PINTO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "General Viamont",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GERMANIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GILBERT",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GOBERNADOR COST",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GOBERNADOR GREG",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GOBERNADOR MACI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GOBERNADOR MACIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Gobernador Mans",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GOBERNADOR ROCA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Gobernador Vira",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GODEKEN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GODOY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GONZALEZ CATAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GR.JOSE G.DE ARTIGAS 117",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL ALVEAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL DANIEL CER",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL FERNANDEZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL GALARZA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL JOSE G ARTIGAS 117",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL LAMADRID",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL LAS HERAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL MOSCONI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL PIZARRO 191",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL. ALVEAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL. ARENALES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL. LAMADRID",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL. PICO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL. PINTO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL. VIAMONTE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL. VILLEGAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAL.VILLEGAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRAND BOURG",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GREGORIA PEREZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRL. CABRERA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GRL. VILLEGAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GUALEGUAY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GUARANI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GUAYMALLEN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GUILLERMO E HUD",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GUILLERMO E. HU",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HASENKAMP",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HERNANDARIAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HERNANDEZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HERRADURA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HERRERA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HINOJO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HINOJO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HIPOLITO IRIGOY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Hipolito Yrigoyen 26",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HOLMBERG",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HUDSON",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HUINCA RENANCO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HUMAHUACA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HUMBERTO 1*",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Humberto Primo",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HUMBOLT",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "HUSARES S/N ESQ.ROSARIO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "IBARRETA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "IBICUY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ICAÑO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "INDEPENDENCIA 2980",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "INDEPENDENCIA N*506",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Ines Indart",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "INES INDARTE-SA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ING.JACOBACCI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "INGENIERO ALLAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "INGENIERO HUERG",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "INRIVILLE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "INT ALVEAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "INT. ALVEAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "INTENDENTE ALVE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "IRIGOYEN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "IRIONDO 406",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ISLA VERDE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ITA IBATE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ITATI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "J B ALBERDI 6151",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "J.M.GUTIERREZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "J.V.GONZALEZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JACINTO ARAUZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JAUREGUI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JAUREGUI JOSE M",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JOAQUIN GORINA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JOAQUIN V GONZA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JOAQUIN V. GONZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JOAQUIN V.GONZA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JOSE BRUNO MORON 3283",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JOSE HERNANDEZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JOSE HERNANDEZ 800",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JOSE INGENIEROS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JOSE LAMY 1360",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JOSE MARMOL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JUAN A.PRADERE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JUAN B.ALBERDI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JUAN BAUTISTA A",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JUAN CARLOS EVANS 148",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JUAN FEL.ARANGUREN 2736",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JUAN JOSE CASTE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JUAN JOSE MICHLIG 317",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JUAN M. GUTIERR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JUAN MARIA GUTI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JUAREZ CELMAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "JUNCAL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Justo Daract",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "L N ALEM 550",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "L OLMOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "L. DE LA TORRE  555",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "L.N.ALEM 550",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA CIENAGUITA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "La Clotilde",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA CRIOLLA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA CRUZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA CUMBRECITA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA FLORIDA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA FRANCIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "La Fuente 1100",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA LEONESA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA LUCILA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA LUCILA DEL M",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA PAZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA PELADA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA PLAYOSA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA PUERTA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA QUIACA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA REDUCCION",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA REJA - MOREN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA RIOJA 287",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA TABLADA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA TORDILLA NOR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA TRINIDAD CHI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA UNION",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA VIOLETA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LABORDE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LABOULAYE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAGO PUELO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Laguna Blanca",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAPRIDA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS BREÃ‘AS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS CATONAS-MOR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS FLORES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS HERAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS HIGUERAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS JUNTURAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS LAJAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS LAJITAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS LOMITAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS PAREJAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Las Peas",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS PENAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS PEÑAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS QUINTAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAS QUINTAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LASTENIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LAVALLE VILLA T",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LEALES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LEANDRO N ALEM",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LEANDRO N. ALEM",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Leandro N.Alem",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LEHMANN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Lezama",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LEZICA Y TORREZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LIBERTADOR GRAL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LISANDRO OLMOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LLAVALLOL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Lobos",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LOBOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LOMA VERDE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LOMAS DE TAFI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LOMAS DEL MIRAD",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Londres",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LORETO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LOS ANTIGUOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LOS CONDORES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LOS GUTIERREZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LOS JURIES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LOS NOGALES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LOS QUIRQUINCHO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LOS RALOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LOS TOLDOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Los Toldos",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LUCAS GONZALEZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LUIS LAGOMARSIN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Lujan de Cuyo",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LULES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "M. ROMERO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MACHAGAI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MACIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MACIEL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MAGDALENA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MAGGIOLO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MAIPU",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MALABRIGO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MALAGUEÃ‘O",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MALAGUEÑO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MALAGUEO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MALAVER-G.SAN M",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Malazan",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MALVINAS ARGENT",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MANSILLA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MANTE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MANUEL ALBERTI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MANUEL ALBERTI-",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MANUEL B. GONNE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MANUEL OCAMPO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MANZANARES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Maq. SAVIO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MAQUIN.SAVIO-ES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MAQUINCHAO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MAQUINISTA F SA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MAQUINISTA SAVI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MAR DEL PLATA 1050",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MAR DEL TUYU",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MARCELINO ESCAL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MARCOS PAZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MARIA GRANDE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MARIA JUANA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MARIA LUISA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MARIA TERESA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MARIANO ACOSTA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MARIANO ALFONZO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MARTIN CORONADO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MARTIN FIERRO 317",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MATHEU",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MATILDE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MAXIMO PAZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MAYOR VILLAFAÃ‘E",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MAZA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MECHITA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MECHITA - BRAGA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MEDANOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MEDRANO 1773",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MELO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MENDIOLAZA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MERCEDES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "METAN-SALTA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MINA CLAVERO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MISIONES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MITRE 857",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MOCORETA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MOISES VILLE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MONJE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MONTE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MONTE BUEY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MONTE CASEROS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MONTE CHINGOLO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MONTE CRISTO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Monte Quemado",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MONTE VERA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MONTERRICO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MOQUEHUA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MORENO-P. DEL R",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MORSE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MURGUIONDO 1873/1875",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "MURPHY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "N. De La Riestr",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Naschel",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "NAVARRO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "NECOCHEA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "NELSON",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "NONOGASTA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "NORBERTO D.L.RI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "NORBERTO DE LA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "NU EZ DEL PRADO 117",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "NUEVA ESPERANZA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "O HIGGINS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "OCAMPO 698",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "OHIGGINS 36",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "OLIVA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "OLMOS 453",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "OLTA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "OPEN DOOR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ORDOÑEZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ORIENTE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ORO VERDE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PABLO PODESTA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PALPALA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PAMPA DEL INDIO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PAMPA DEL INDIO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PAMPAYASTA SUR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PARADA ROBLES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PARANA // ENTRE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PASCANAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PASCO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PASCO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PASCUAL VELEZ 2096",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PASO DE LA PATR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PASTEUR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PATRICIO DIEZ 217",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PAVON",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PAVON ARRIBA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PAZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PELLEGRINI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Perdriel",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PEREZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PEREZ MILLAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PERITO MORENO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PERU 283",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PICHANAL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PICO TRUNCADO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PIEDRA BLANCA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PIEDRA DEL AGUI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PIEDRITAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Pieyro",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PIGUE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PILA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PILA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PINAMAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PIÑEYRO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PIQUILLIN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PIRAGNE NIVEYRO 2253",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PLATANOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "POCITOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "POETA RISSO 3306",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "POMAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PONTEVEDRA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PONTEVEDRA - ME",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "POTOSI 3248 ESQ.VILLEGAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "POZO AZUL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "POZO DEL MOLLE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PRESIDENCIA DE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PRESIDENTE DERQ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PRONUNCIAMIENTO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PTE H YRIGOYEN 499",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PTO.GRAL.SAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUEBLO ANDIN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUEBLO ARRUA ES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Pueblo Diaz",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUEBLO DOYLE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUERTO ESPERANZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUERTO GRAL SAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUERTO IGUAZU",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUERTO PIRAY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUERTO SAN JULI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUEYRREDON",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUJATO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUNILLA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUNILLA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUNTA LARA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "QUEMU QUEMU",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "QUEMU QUEMU",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "QUEMU QUEMU",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "QUENUMA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "QUIMILI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "QUIMILI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "QUINES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "R.DE LOS SAUCES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RAFAEL CALZADA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Rafael Castillo",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Rafael Castillo",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RAFAEL OBLIGADO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RANCHOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RANELAGH",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RAUCH",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RAWSON",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RECONQUISTA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RECONQUISTA 1015",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RIACHUELO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RICARDO ROJAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RICARDONE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RINCON DE LOS S",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RINGUELET",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RIO COLORADO-TU",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RIVADAVIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RIVADAVIA 196",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ROBERTS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ROGELIO TERRE 755",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ROJAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ROMANG",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ROQUE  PEREZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ROQUE PEREZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Roque Saenz Pea",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ROSARIO DE LA F",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ROSARIO DE LERM",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ROSARIO DEL TAL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RUFINO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "RUTA 11 23050",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "S.C. BUENA VIST",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "S.C.B. Vista",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "S.J.DE FELICIAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "S.J.DE LA ES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "S.J.FRONTERA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SA PEREYRA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAAVEDRA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SACANTA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SALADAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SALADILLO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SALADILLO 2016",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SALDAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SALDUNGARAY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SALSACATE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SALTO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SALTO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SALTO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SALTO ENCANTADO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SALVADOR MARIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN A. DE GILES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN A. DE PADUA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN AGUSTIN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN ANTONIO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN BASILIO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN BENITO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN BERNARDO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN CARLOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "San Carlos Mina",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN CAYETANO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN COSME",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN FRANCISCO D",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN GENARO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN GREGORIO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "San Ignacio",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN J.DE LA ESQ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN JAIME DE LA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN JAVIER",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN JERONIMO NORTE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN JORGE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN JOSE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN JOSE DE FEL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN JOSE DE LA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN JOSE DEL RI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN JUAN BAUTIS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN LORENZO 761",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN LORENZO- SA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN LUIS ( SALT",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN LUIS DEL PA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN MARCOS SUD",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN MARTIN  179",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN MARTIN 1272",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "San Martin 259",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN MARTIN 356",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN MARTIN 455",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN MARTIN 599",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN MIGUEL DEL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN PATRICIO DE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN RAFAEL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN RAMON DE LA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "San Roque",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANCTI SPIRITU",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTA ANA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTA ANITA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Santa Clara  de",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTA ISABEL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTA LUCIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTA MARIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTA MARIA -CA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTA MARIA DE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTA TERESA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTA TERESITA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTANDER 5101",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTIAGO JORGE BYNON 3180",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTIAGO TEMPLE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTO DOMINGO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTOS LUGARES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SARGENTO CABRAL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SARMIENTO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SARMIENTO 300",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SARMIENTO 74",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SARMIENTO 799",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SASTRE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAUCE DE LUNA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAUJIL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SEBASTIAN ELCAN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Seeber",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SELVA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SERODINO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SIMOCA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SINSACATE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SOMELLERA 5725",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SOMELLERA 5725",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SOURDEAUX -G.SA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "STA. ROSA DE CA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "STA.MARIA-SAN M",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "STEGMAN 700",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "STROBEL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "STROEDER",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SUARDI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SUIPACHA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Susana",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SUSANA (STA FE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SUSQUES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TACO POZO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TACO RALO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Tama",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TANCACHA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TANTI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TAPALQUE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TATACUA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TICINO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TIMBUES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TOAY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TOLOSA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Tortuguitas",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TOTORAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TRANSITO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TRENQUE LAUQUEN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TRES ALGARROBOS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TRES ISLETAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TRES LOMAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TREVELIN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TRUJUI - PDO. M",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TURDERA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "UBAJAY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "UCACHA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "URDINARRAIN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "URIBELARREA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "V DE MAYO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "V.DEL ROSARIO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VALDES - 25 DE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VALERIA DEL MAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VAQUEROS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VEINTICINCO DE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Venezuela 2726",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VERONICA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VERONICA-PUNTA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VIALE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VICTORI. DE LA PLAZA 1137",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VICTORIA-SAN FE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VICTORICA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VICUÑA MACKENNA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VICUÑA MAKENA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VIEYTES 60",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Villa Astolfi",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA BONICH",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA CA¥AS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA CAÂ¥AS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA CACIQUE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA CAÑAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA CLARA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA DE LAS RO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA DE MARIA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA DE MAYO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA DE SOTO",
      "zona": "CORDOBA",
      "apm": "VIVIANA SOSA"
    },
    {
      "localidad": "VILLA DEL DIQUE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA DEL ROSAR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA DELROSARI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA DOMINICO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA DOS TRECE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA ELVIRA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA ELVIRA -",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA GENERAL B",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA GENERAL G",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA GESELL",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA GRANADERO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA HIPODROMO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA HUIDOBRO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA INSUPERAB",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA IRIS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA LA FLORID",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Villa Lamadrid",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA LEALES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA MAIPU",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA MAIPU -",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA MANTERO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA MARIANO M",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA MERLO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA MINETTI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA MOQUEHUA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA MUGHET",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA MUGUETA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA NUEVA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA NUEVA DE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA OCAMPO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA PROGRESO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA RAFFO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA ROSA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA ROSA - PI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA SAN LOREN",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA TRINIDAD",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Villa Union",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Villada",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLAS CIUDAD D",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VIRGEN DEL CARMEN S/N 0",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VIRREY DEL PINO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Vista Flores",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "WANDA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "WHEELWRIGHT",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "WILLIAM C. MORR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "WILLIAM MORRIS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ZABALLA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ZAVALLA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ARROYO LEYES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SANTA ROSA-FLOR",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Puerto Santa Cr",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Pueblo Irigoyen",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Noetinger",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "General Vedia",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ADOLFO GONZALES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA PARQUE SI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "SAN FRANCISCO (",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA CONSTI",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CORONEL BOGA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "CNEL. SUAREZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GLORIA DE LA PE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "",
      "zona": "",
      "apm": "SIN FICHERO"
    },
    {
      "localidad": "CITY BELL - LA",
      "zona": "LT",
      "apm": "GBA.SUR"
    },
    {
      "localidad": "ALTO ALEGRE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ESSTACION ARAOZ",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "FERNANDEZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GANNING",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "GONZALES CHAVES",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Sta Rosa De Cal",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "TORTUGAS",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA DEL PRADO",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA SANTOS TESEI",
      "zona": "OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "JUANA KOSLAY",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LA ESPERANZA- S",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AV FEDERICO LACROZE 3642",
      "zona": "CABA NORTE",
      "apm": "CABA NORTE"
    },
    {
      "localidad": "AV  CORDOBA 1402",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "HUMBERTO PRIMO 434",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AV, RIVADAVIA 8509",
      "zona": "CABA OESTE",
      "apm": "GEORGINA GALLI"
    },
    {
      "localidad": "AV PUEYRREDON 539",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "AEROPARQ J.NEWBERY Y AV RAF.OBLIG",
      "zona": "CABA",
      "apm": "IGNACIO GANCI"
    },
    {
      "localidad": "LA FLORIDA- TUC",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ICAÃ‘O DE LA PA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "S.M.TUCUMAN",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "COLONIA BARON",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "VILLA LDOR SAN MARTIN",
      "zona": "ER",
      "apm": "LUIS BONFILS"
    },
    {
      "localidad": "SAIRA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Carpinteria",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "TUCUMAN",
      "zona": "TUCU",
      "apm": "ALEJANDRO LEVY"
    },
    {
      "localidad": "LOS HORNOS -LA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "LOS PUESTOS- LE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PLAYA UNION",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "POTRERO DE FUNE",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "AZARA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "ESTACION GENERA",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Tacural",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "Maria Susana",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "BARKER-CLARAZ",
      "zona": "ZNV",
      "apm": "SIN APM"
    },
    {
      "localidad": "PUAN",
      "zona": "DDBB",
      "apm": "DIEGO HECHTLINGER"
    },
    {
      "localidad": "SANTA MARIA- CA",
      "zona": "ZNV",
      "apm": "SIN APM"
    }
  ]