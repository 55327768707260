export function groupByLocationAndFilterByAPM(array, apmName) {
  // Primero, filtramos el array para incluir solo los elementos del APM especificado
  const filteredArray = array.filter(item => item.apm === apmName);

  // Luego, agrupamos por localidad
  const groupedByLocation = filteredArray.reduce((acc, curr) => {
    const location = curr.localidad.trim();
    if (!acc[location]) {
      acc[location] = {
        localidad: location,
        cantidad: 0,
        droguerias: new Set(),
        productos: new Set(),
        farmacias: new Set()
      };
    }
    acc[location].cantidad += curr.cantidad;
    acc[location].droguerias.add(curr.drogueria);
    acc[location].productos.add(curr.producto);
    acc[location].farmacias.add(curr.farmacia.trim());
    return acc;
  }, {});

  // Convertimos el objeto resultante en un array y transformamos los Sets en arrays
  return Object.values(groupedByLocation).map(item => ({
    ...item,
    droguerias: Array.from(item.droguerias),
    productos: Array.from(item.productos),
    farmacias: Array.from(item.farmacias)
  }));
}

