import React from "react";

const Select = ({ title = "Select Field", name, isRequired = true, id, onchange, options = ["Option 1", "Option 2"] }) => {
	return (
		<div className="div">
			<label className="label">
				<span className="label-text">{title}</span>
			</label>
			<select
				id={id}
				defaultValue={`Elija una opción`}
				className="select select-bordered select-xs h-[38px] rounded-[4px]  w-full max-w-xs"
				name={name}
				isRequired={isRequired}
				onChange={onchange}>
				<option disabled>Elija una opción</option>

				{options.map((option, index) => (
					<option key={index}>{option}</option>
				))}
			</select>
		</div>
	);
};

export default Select;
