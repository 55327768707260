import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BiEdit } from "react-icons/bi";
import Input from "../form/Input";
import ModalHeading from "../headings/ModalHeading";
import ModalCloseButton from "./ModalCloseButton";
import SaveButton from "./SaveButton";
import { toast } from "react-toastify";
import { BACKEND_URL } from "../../constants/constants";
import { ReloadDataContext } from "../../contexts/reloadDataContext";

const EditButtonMoviment = ({ moviment, setModal }) => {
	const [quantity, setquantity] = useState(moviment.quantity);
	const [remito, setRemito] = useState(moviment.referNumber);

	const { setReloadPending } = useContext(ReloadDataContext);

	const toggleModal = () => {
		setModal(false);
	};

	const updateProduct = (event) => {
		event.preventDefault();

		// send data to server
		fetch(`${BACKEND_URL}/moviments/updatePending/${moviment.id}`, {
			method: "PUT",
			headers: {
				"content-type": "application/json",
			},
			body: JSON.stringify({
				quantity: `${quantity}`,
				referNumber: `${remito}`,
			}),
		})
			.then((res) => {
				toast.success(<span>Producto actualizado 🚀.</span>);
				setReloadPending((prev) => !prev);
				setModal((prev) => !prev);
				res.json();
			})
			.catch(() => toast.error("Hubo un error al intentar actualizar el producto 😢"));
	};

	return (
		<>
			<div className="w-[100%] h-[100vh] fixed  left-0 top-0 flex  z-[100000000] justify-center items-center ">
				<div
					onClick={toggleModal}
					className="w-[100%] h-[100vh] fixed  z-[1] bg-[#00000070] left-0 top-0 flex justify-center items-center "></div>

				<label className="lg:w-7/12 md:w-10/12 w-11/12 max-w-4xl absolute z-[2] bg-[#ffffff] rounded-lg p-4" htmlFor="">
					<div onClick={toggleModal}>
						<ModalCloseButton />
					</div>

					<ModalHeading modalHeading={`${moviment.product}`} />

					<form onSubmit={updateProduct}>
						<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-x-4 gap-y-1 mb-2">
							<Input
								value={quantity}
								onchange={(e) => {
									setquantity(e.target.value);
								}}
								title={"Cantidad"}
								type="number"
								placeholder="250"
								name="quantity"
								isRequired="required"
							/>
							<Input
								value={remito}
								onchange={(e) => {
									setRemito(e.target.value);
								}}
								title={"Remito"}
								type="text"
								placeholder="X-0001-0000"
								name="remito"
								isRequired="required"
							/>
						</div>
						<div onClick={(e) => updateProduct(e)}>
							<SaveButton extraClass={"mt-4 w-full"} />
						</div>
					</form>
				</label>
			</div>
		</>
	);
};

export default EditButtonMoviment;
