import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Search, QrCode, Edit2, Edit, Save } from "lucide-react"; // Iconos para búsqueda y QR
import { BACKEND_URL } from "../constants/constants";
import QRCode from "qrcode"; // Librería para generar QR
import { jsPDF } from "jspdf";
import {Html5QrcodeScanner} from "html5-qrcode"; // Librería para escanear códigos QR

// Componente para el escáner QR en un modal
const ScannerModal = ({ isOpen, onClose, onDetected }) => {
  useEffect(() => {
    if (isOpen) {
      const scanner = new Html5QrcodeScanner("reader", { fps: 10, qrbox: 250 });

      scanner.render(
        (decodedText) => {
          console.log(`Código QR detectado: ${decodedText}`);
          onDetected(decodedText); // Llama al manejador cuando se detecta un código QR
          onClose(); // Cierra el modal cuando se detecta el QR
        },
        (error) => {
          console.error("Error al escanear el QR: ", error);
        }
      );

      return () => {
        scanner.clear(); // Limpia el escáner cuando el componente se desmonta
      };
    }
  }, [isOpen, onDetected, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg relative">
        <h2 className="text-2xl font-bold mb-4">Escanea el código QR</h2>
        <div id="reader" style={{ width: "320px" }} />
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

// Componente para el modal con el código QR generado
const ZoneModal = ({ selectedSection }) => {
  const qrRef = useRef(null);

  useEffect(() => {
    if (selectedSection) {
      // Generar el código QR
      QRCode.toCanvas(qrRef.current, selectedSection.id, { width: 200 }, (error) => {
        if (error) console.error("Error al generar el QR:", error);
      });
    }
  }, [selectedSection]);

  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    // Obtener la imagen del canvas
    const qrImage = qrRef.current.toDataURL("image/png");

    // Agregar la imagen al PDF
    doc.text(`Zona: ${selectedSection.id}`, 10, 10); // Añadir un texto al PDF (nombre de la zona)
    doc.addImage(qrImage, "PNG", 10, 20, 100, 100); // Añadir el código QR al PDF

    // Descargar el PDF
    doc.save(`CodigoQR_${selectedSection.id}.pdf`);
  };

  return (
    <div>
      <canvas ref={qrRef} style={{ display: 'none' }}></canvas>
      <button
        onClick={handleDownloadPDF}
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
      >
        Descargar código QR
      </button>
    </div>
  );
};


const sections = [
	{ id: "A", color: "bg-white", gridArea: "A", label: "A", clickable: true },
	{ id: "B", color: "bg-white", gridArea: "B", label: "B", clickable: true },
	{ id: "C", color: "bg-white", gridArea: "C", label: "C", clickable: true },
	{ id: "D", color: "bg-white", gridArea: "D", label: "D", clickable: true },
	{ id: "E", color: "bg-white", gridArea: "E", label: "E", clickable: true },
	{ id: "F", color: "bg-white", gridArea: "F", label: "F", clickable: true },
	{ id: "G", color: "bg-white", gridArea: "G", label: "G", clickable: true },
	{ id: "H", color: "bg-white", gridArea: "H", label: "H", clickable: true },
	{ id: "I", color: "bg-white", gridArea: "I", label: "I", clickable: true },
	{ id: "J", color: "bg-white", gridArea: "J", label: "J", clickable: true },
	{ id: "K", color: "bg-white", gridArea: "K", label: "K", clickable: true },
	{ id: "L", color: "bg-white", gridArea: "L", label: "L", clickable: true },
	{ id: "M", color: "bg-white", gridArea: "M", label: "M", clickable: true },
	{ id: "N", color: "bg-white", gridArea: "N", label: "N", clickable: true },
	{ id: "O", color: "bg-white", gridArea: "O", label: "O", clickable: true },
	{ id: "P", color: "bg-white", gridArea: "P", label: "P", clickable: true },
	{ id: "Q", color: "bg-white", gridArea: "Q", label: "Q", clickable: true },
	{ id: "R", color: "bg-white", gridArea: "R", label: "R", clickable: true },
	{ id: "S", color: "bg-white", gridArea: "S", label: "S", clickable: true },
	{ id: "T", color: "bg-white", gridArea: "T", label: "T", clickable: true },
	{ id: "U", color: "bg-white", gridArea: "U", label: "U", clickable: true },
	{ id: "V", color: "bg-white", gridArea: "V", label: "V", clickable: true },
	{ id: "W", color: "bg-white", gridArea: "W", label: "W", clickable: true },
	{ id: "X", color: "bg-white", gridArea: "X", label: "X", clickable: true },
	{ id: "Y", color: "bg-white", gridArea: "Y", label: "Y", clickable: true },
	{ id: "Z", color: "bg-white", gridArea: "Z", label: "Z", clickable: true },
	{ id: "AA", color: "bg-white", gridArea: "AA", label: "AA", clickable: true },
	{ id: "AB", color: "bg-white", gridArea: "AB", label: "AB", clickable: true },
	{ id: "AC", color: "bg-white", gridArea: "AC", label: "AC", clickable: true },
	{ id: "AE", color: "bg-white", gridArea: "AE", label: "AE", clickable: true },
	{ id: "AF", color: "bg-white", gridArea: "AF", label: "AF", clickable: true },
	{ id: "AI", color: "bg-white", gridArea: "AI", label: "AI", clickable: true },
	{ id: "AJ", color: "bg-white", gridArea: "AJ", label: "AJ", clickable: true },
	{ id: "AK", color: "bg-white", gridArea: "AK", label: "AK", clickable: true },
	{ id: "AO", color: "bg-white", gridArea: "AO", label: "AO", clickable: true },
	{ id: "AP", color: "bg-white", gridArea: "AP", label: "AP", clickable: true },
	{ id: "AQ", color: "bg-white", gridArea: "AQ", label: "AQ", clickable: true },
	{ id: "AR", color: "bg-white", gridArea: "AR", label: "AR", clickable: true },
	{ id: "AS", color: "bg-white", gridArea: "AS", label: "AS", clickable: true },
	{ id: "CAJAS-CON-PRODUCTOS", color: "bg-orange-500", gridArea: "CAJAS-CON-PRODUCTOS", label: "", clickable: false },
	{ id: "CAJAS-VACIAS", color: "bg-slate-500", gridArea: "CAJAS-VACIAS", label: "", clickable: false },
	{ id: "CAJA-CON-BOLSAS", color: "bg-blue-500", gridArea: "CAJA-CON-BOLSAS", label: "", clickable: false },
	{ id: "CAJAS-VACIASS", color: "bg-slate-500", gridArea: "CAJAS-VACIASS", label: "", clickable: false },
	{ id: "CAJAS-VACIASSS", color: "bg-slate-500", gridArea: "CAJAS-VACIASSS", label: "", clickable: false },
	{ id: "PALET-CON-CAJAS-VACIAS", color: "bg-yellow-800", gridArea: "PALET-CON-CAJAS-VACIAS", label: "", clickable: false },
	{ id: "PALET-Y-CAJAS-VACIAS", color: "bg-pink-300", gridArea: "PALET-Y-CAJAS-VACIAS", label: "", clickable: false },
	{ id: "CAJA-CON-PRODUCTO", color: "bg-orange-500", gridArea: "CAJA-CON-PRODUCTO", label: "", clickable: false },
	{ id: "PALET-Y-CAJAS-VACIASS", color: "bg-yellow-800", gridArea: "PALET-Y-CAJAS-VACIASS", label: "", clickable: false },
	{ id: "PALET-Y-CAJAS-VACIASSS", color: "bg-yellow-800", gridArea: "PALET-Y-CAJAS-VACIASSS", label: "", clickable: false },
	{ id: "PALET-Y-CAJAS-VACIASSSSS", color: "bg-yellow-800", gridArea: "PALET-Y-CAJAS-VACIASSSSS", label: "", clickable: false },
];

const legendItems = [
	{ color: "bg-orange-500", label: "Cajas con producto" },
	{ color: "bg-yellow-800", label: "Palet con cajas vacias" },
	{ color: "bg-blue-500", label: "Caja con bolsas" },
	{ color: "bg-slate-500", label: "Cajas Vacias" },
	{ color: "bg-pink-300", label: "Palet y cajas vacias" },
];

export default function WarehouseMap() {
	const [selectedSection, setSelectedSection] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [currentLocationIndex, setCurrentLocationIndex] = useState(0);
	const [highlightedLocations, setHighlightedLocations] = useState([]);
	const [suggestions, setSuggestions] = useState([]);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const searchRef = useRef(null);
	const [products, setProducts] = useState([]);
	const [isScannerOpen, setIsScannerOpen] = useState(false);





	const [editingIndex, setEditingIndex] = useState(null); // Índice del estante que está siendo editado
  const [editedProductName, setEditedProductName] = useState(""); // Nombre del producto editado
  const [editingShelf, setEditingShelf] = useState(""); // Nombre del estante que está siendo editado

  const [recharge, setRecharge] = useState(false);
  
  const handleEditClick = (index, productName, shelfName) => {
    setEditingIndex(index);
    setEditedProductName(productName);
    setEditingShelf(shelfName);
  };
  
  const handleSaveClick = async (index, shelfName) => {
    // Aquí se debería realizar la llamada a la API para actualizar el estante correspondiente
    const updatedData = {
      estante: shelfName,      // Estante (estante_1, estante_2, etc.)
      nuevoNombre: editedProductName, // Nuevo nombre del producto
    };

   setEditingIndex(null);
   setEditedProductName("");
   setEditingShelf("");

	try {
		const response = await fetch(`${BACKEND_URL}/zonas/upZonas/${selectedSection.id}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(updatedData),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		const data = await response.json();
		console.log(data);
		setRecharge(!recharge);
		setSelectedSection(null);
	} catch (error) {
		console.error(error);
	}

  };



	

  const fetchProducts = async () => {
	try {
		const response = await fetch(`${BACKEND_URL}/zonas/getZonas`);
		const data = await response.json();
		// Convertir el formato para que los estantes se manejen como una lista de productos por zona
		const formattedData = data.map((zone) => ({
			name: [
				zone.estante_1,
				zone.estante_2,
				zone.estante_3,
				zone.estante_4,
				zone.estante_5,
			],
			locations: [zone.nombre_zona], // Ahora las zonas son individuales
		}));
		setProducts(formattedData);
	} catch (error) {
		console.error(error);
	}
};

// Recargar productos al montar el componente
useEffect(() => {
	fetchProducts();
}, [recharge]);
	  

	useEffect(() => {
		if (searchTerm) {
			const results = products.filter((product) => product.name.some((p) => p.toLowerCase().includes(searchTerm.toLowerCase())));
			setSuggestions(results.flatMap((product) => product.name));
			setShowSuggestions(true);
		} else {
			setSuggestions([]);
			setShowSuggestions(false);
		}
	}, [searchTerm]);

	const handleSearch = (e) => {
		e.preventDefault();
		if (searchTerm) {
			const results = products.filter((product) => product.name.some((p) => p.toLowerCase().includes(searchTerm.toLowerCase())));
			setSearchResults(results);
			const allLocations = results.flatMap((result) => result.locations);
			setHighlightedLocations(allLocations);
		} else {
			setSearchResults([]);
			setHighlightedLocations([]);
		}
		setShowSuggestions(false);
	};

	const handleSectionClick = (section) => {
		if (section.clickable) {
			const sectionResults = products.filter((product) => product.locations.includes(section.id));
			setSearchResults(sectionResults);
			setSelectedSection(section);
			setHighlightedLocations(sectionResults.flatMap((result) => result.locations));
			setCurrentLocationIndex(0);
		}
	};

	const handleBackClick = () => {
		setSelectedSection(null);
		setCurrentLocationIndex(0);
	};

	const handleSuggestionClick = (suggestion) => {
		setSearchTerm(suggestion);
		setShowSuggestions(false);
		searchRef.current.focus();
	};

	const handleQrDetected = (code) => {
		const section = sections.find((s) => s.id === code);
		if (section) {
		  const sectionResults = products.filter((product) => product.locations.includes(section.id));
		  setSearchResults(sectionResults);
		  setSelectedSection(section);
		  setIsScannerOpen(false); // Cerrar el modal del escáner al detectar el QR
		} else {
		  alert("Sección no encontrada");
		}
	  };

	  const shelfNames = ["estante_1", "estante_2", "estante_3", "estante_4", "estante_5"];

	return (
		<div className="relative w-full min-h-screen p-8 mt-16">
			<div className="sticky top-0 z-10 bg-white pb-4">
			<form onSubmit={handleSearch} className="relative flex items-center gap-2">
          <input
            type="text"
            placeholder="Buscar producto..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-300"
            ref={searchRef}
          />
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <Search className="w-4 h-4" />
          </button>
          <button
            type="button"
            onClick={() => setIsScannerOpen(true)}
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            <QrCode className="w-4 h-4" />
          </button>
        </form>
		{showSuggestions && suggestions.length > 0 && (
						<ul className="absolute z-20 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto mt-1">
							{suggestions.map((suggestion, index) => (
								<li
									key={index}
									className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
									onClick={() => handleSuggestionClick(suggestion)}>
									{suggestion}
								</li>
							))}
						</ul>
					)}
			</div>

			  {/* Modal del escáner QR */}
			  <ScannerModal
        isOpen={isScannerOpen}
        onClose={() => setIsScannerOpen(false)}
        onDetected={handleQrDetected}
      />

			<AnimatePresence>
				{selectedSection && (
					<motion.div
						key="detail"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.3 }}
						className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-20 p-8">
						<div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full">
							<h2 className="text-4xl font-bold mb-4">{`Sección ${selectedSection.id}`}</h2>
							{searchResults.length > 0 && (
								<>
									<table className="table-auto border-collapse border border-gray-300 mx-auto mb-4">
										<thead>
											<tr>
												<th className="border border-gray-300 px-4 py-2">Nombre del Producto</th>
											</tr>
										</thead>
										<tbody>
											{searchResults[currentLocationIndex].name.map((productName, index) => (
 <tr key={index}>
 <td className="border border-gray-300 px-4 py-2">
   {editingIndex === index ? (
	 <input
	   type="text"
	   value={editedProductName}
	   onChange={(e) => setEditedProductName(e.target.value)}
	   className="px-2 py-1 border border-gray-300 rounded-md"
	 />
   ) : (
	 productName
   )}
 </td>
 <td className="border border-gray-300 px-4 py-2">
   {editingIndex === index ? (
	 <button
	   onClick={() => handleSaveClick(index, shelfNames[index])}
	   className="flex justify-center items-center text-green-500"
	 >
	   <Save className="w-4 h-4" />
	   Guardar
	 </button>
   ) : (
	 <button
	   onClick={() => handleEditClick(index, productName, shelfNames[index])}
	   className="flex justify-center items-center text-blue-500"
	 >
	   <Edit className="w-4 h-4" />
	   Editar
	 </button>
   )}
 </td>
</tr>
											))}
										</tbody>
									</table>
								</>
							)}
							<div className="flex justify-between">
							<button
								onClick={handleBackClick}
								className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
								Volver
							</button>
							<ZoneModal selectedSection={selectedSection} />
							</div>
						</div>
					</motion.div>
				)}
			</AnimatePresence>

			<motion.div
				key="map"
				className="grid grid-cols-18 grid-rows-9 gap-1 h-[calc(100vh-12rem)] mt-4"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.5 }}
				style={{
					gridTemplateAreas: `
            "A CAJAS-CON-PRODUCTOS D G CAJAS-VACIAS J M CAJA-CON-BOLSAS P S CAJAS-VACIASS V Y CAJAS-VACIASSS AB PALET-Y-CAJAS-VACIAS AE AE"
            "A CAJAS-CON-PRODUCTOS D G CAJAS-VACIAS J M . P S . V Y CAJAS-VACIASSS AB PALET-Y-CAJAS-VACIAS AF AF"
            "B CAJAS-CON-PRODUCTOS E H CAJAS-VACIAS K N . Q T . W Z CAJAS-VACIASSS AC PALET-Y-CAJAS-VACIAS .  CAJA-CON-PRODUCTO"
            "B CAJAS-CON-PRODUCTOS E H CAJAS-VACIAS K N . Q T . W Z CAJAS-VACIASSS AC . AI AI"
            "C CAJAS-CON-PRODUCTOS F I CAJAS-VACIAS L O . R U . X AA CAJAS-VACIASSS PALET-CON-CAJAS-VACIAS . AJ AJ"
            "C CAJAS-CON-PRODUCTOS F I CAJAS-VACIAS L O . R U . X AA CAJAS-VACIASSS PALET-CON-CAJAS-VACIAS . . ."
            ". . . . . . . . . . . . . . . . . ."
            ". . . . . AQ . . . . . . . . . . . ."
            "AS AS AR AR . AQ AP AP AO AO PALET-Y-CAJAS-VACIASSSSS . PALET-Y-CAJAS-VACIASSS PALET-Y-CAJAS-VACIASSS PALET-Y-CAJAS-VACIASS PALET-Y-CAJAS-VACIASS AK AK"
          `,
				}}>
				{sections.map((section) => (
					<motion.div
						key={section.id}
						style={{
							gridArea: section.gridArea,
						}}
						className={`flex items-center justify-center text-lg font-bold ${
							section.clickable ? "cursor-pointer" : "cursor-default"
						} ${section.color} ${
							highlightedLocations.includes(section.id) ? "ring-4 ring-green-500" : ""
						} shadow-lg overflow-hidden rounded-lg`}
						whileHover={section.clickable ? { scale: 0.95 } : {}}
						whileTap={section.clickable ? { scale: 0.95 } : {}}
						onClick={() => handleSectionClick(section)}>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ delay: 0.2 }}
							className="h-full w-full flex items-center justify-center">
							{section.label}
						</motion.div>
					</motion.div>
				))}
			</motion.div>

			<div className="mt-8">
				<h3 className="text-xl font-bold mb-2">Leyenda</h3>
				<div className="flex flex-wrap gap-4">
					{legendItems.map((item, index) => (
						<div key={index} className="flex items-center">
							<div className={`w-6 h-6 mr-2 rounded ${item.color}`}></div>
							<span>{item.label}</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
