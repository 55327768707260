export const roles = {
    INITIAL: 'apms',
    RRHH: 'rrhh',
    COMERCIALLEADER: 'comercialLeader',
    MARKETINGlEADER: 'marketingLeader',
    STOCKLEADER: 'stockLeader',
    SUPERADMIN: 'superAdmin',
    DCPOINTER: 'dcPointer',
    ADIMINISTRATIVELEADER: 'administrativeLeader',
    POLLITO: 'pollito'
}