import React, { useContext, useState } from "react";
import Select from "../form/Select";
import SelectData from "../form/SelectData";
import { DatePicker } from "../Buttons/datePicker";
import ModalCloseButton from "../Buttons/ModalCloseButton";
import ModalHeading from "../headings/ModalHeading";
import { toast } from "react-toastify";
import { BACKEND_URL } from "../../constants/constants";
import Input from "../form/Input";
import TableRow from "../TableRow";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useAuth } from "../../hooks/useAuth";
import { ReloadDataContext } from "../../contexts/reloadDataContext";

export default function NewMoviment({ products: productsReceived, createModalToggle }) {
	const [date, setdate] = useState(new Date().toLocaleString("es-ar"));
	const { role } = useAuth();
	const [typeOfMoviment, settypeOfMoviment] = useState("");
	const [suplierName, setSuplierName] = useState("");
	const [responsable, setresponsable] = useState(role.name);
	const [products, setproducts] = useState([]);
	const [newProduct, setNewproduct] = useState("");
	const [enterpryse, setenterpryse] = useState("ByDerm");
	const [quantity, setquantity] = useState("");
	const [lot, setlot] = useState("");
	const [expiration, setexpiration] = useState("");
	const [referNumber, setreferNumber] = useState("");
	const [typeOfProduct, setTypeOfProduct] = useState("");
	const [typeOfAdressee, setTypeOfAdressee] = useState("");
	const [adresseeName, setAdresseeName] = useState("");
	const [productionOrder, setProductionOrder] = useState("");
	const [typeOfEntry, setTypeOfEntry] = useState("");

	const { setReloadMovement } = useContext(ReloadDataContext);

	const handleChangeNewProduct = (option) => {
		setNewproduct(option);
	};

	const handleClearProduct = () => {
		setNewproduct("");
	};

	const addNewMoviment = async (event) => {
		event.preventDefault();

		if (!typeOfMoviment) {
			toast.error("Debes cargar un tipo de movimiento 😢");
			return;
		}
		if (products.length === 0) {
			toast.error("Debes cargar productos antes de agregar un movimiento 😢");
			return;
		}

		const productDetails = products.map((product) => ({
			date,
			typeOfMoviment,
			suplierName: suplierName,
			responsable,
			product: product.product,
			enterpryse,
			quantity: typeOfMoviment === "SALIDA" ? `-${product.quantity}` : product.quantity,
			lot,
			expiration,
			referNumber,
			adresseeName,
			productionOrder,
			typeOfProduct,
			typeOfAdressee,
			active: role.role === "pollito",
		}));

		console.log("productDetails", productDetails);

		try {
			createModalToggle();
			const endpoint = role.role === "pollito" ? `${BACKEND_URL}/moviments/addPending` : `${BACKEND_URL}/moviments/add`;

			const response = await fetch(endpoint, {
				method: "POST",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify({ products: productDetails }),
			});

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			await response.json();
			toast.success("Movimiento cargado con éxito 💪");
			setproducts([]); // Clear products after successful submission
			setReloadMovement((prev) => !prev);
		} catch (error) {
			toast.error("Hubo un error al agregar el movimiento 😢");
		}
	};

	// Función para filtrar productos en base al tipo de ingreso
	const filteredProducts = () => {
		if (!typeOfEntry) {
			return productsReceived; // Si no hay tipo de ingreso, muestra todos los productos
		}

		if (typeOfEntry.includes("TESTERS")) {
			// Filtra solo productos que contengan "tester" o "testers" en su nombre
			return productsReceived.filter((product) => /testers?/i.test(product.name));
		}

		if (typeOfEntry.includes("ROTO")) {
			// Filtra solo productos que contengan "roto" en su nombre
			return productsReceived.filter((product) => /roto/i.test(product.name));
		}

		// Si el tipo de ingreso no es "TESTERS" ni "ROTO", devuelve todos los productos
		return productsReceived;
	};

	return (
		<div className="absolute w-full h-screen left-0 top-0 flex justify-center items-center">
			<div className="bg-[#00000070] w-full h-full fixed left-0 top-0 z-[1000]" onClick={() => createModalToggle()}></div>

			<label htmlFor="create-new-product" className=" cursor-pointer z-[100010] bg-[white] rounded-[4px] py-4">
				<label className=" lg:w-10/12 md:w-11/12 w-full max-w-5xl relative" htmlFor="">
					<ModalCloseButton modalId={"create-new-product"} onClick={createModalToggle} />

					<ModalHeading modalHeading={"CARGAR ENTRADA O SALIDA DE PRODUCTOS"} />

					<form onSubmit={(e) => addNewMoviment(e)} className="mx-8">
						<div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 mb-2">
							<div className="col-span-2">
								<Select
									onchange={(e) => settypeOfMoviment(e.target.value)}
									title={"Tipo de Movimiento"}
									name="category"
									isRequired="required"
									options={["ENTRADA", "SALIDA", "ENTRADA S/ REMITO"]}
								/>
							</div>
							{typeOfMoviment === "ENTRADA" && (
								<div className="col-span-1">
									<Select
										onchange={(e) => setTypeOfEntry(e.target.value)}
										title={"Tipo de ingreso"}
										type="text"
										name="pharmacyName"
										isRequired="required"
										options={[
											"DISPROFARMA TESTERS",
											"FCIA TESTERS",
											"CLIENTE TESTERS",
											"DISPROFARMA ROTO",
											"FCIA ROTO",
											"CLIENTE ROTO",
											"FARMACIA",
											"MEDICO",
											"APM",
											"DROGUERIA",
											"LABORATORIO",
											"DISPROFARMA",
											"TIENDA NUBE",
											"ATENCION MEDICO",
											"ATENCION PERSONAL",
											"COSMIATRA"
										]}
									/>
								</div>
							)}

							<div className="col-span-2">
								<SelectData
									onchange={handleChangeNewProduct}
									handleClear={handleClearProduct}
									title={"Nombre de articulo"}
									type="text"
									placeholder="26004 - Cleanser Aqua"
									name="aricleName"
									isRequired="required"
									value={newProduct}
									id={"product"}
									options={filteredProducts() // Llama a la función para obtener los productos filtrados
										.map((c) => c.name.toUpperCase())
										.sort((x, y) => {
											if (x > y) return 1;
											else return -1;
										})}
								/>
							</div>

							<Input
								onchange={(e) => setquantity(e.target.value)}
								title={"Cantidad"}
								type="number"
								placeholder="250"
								name="cantity"
								id={"quantity"}
							/>
							<button
								onClick={(e) => {
									e.preventDefault();
									setproducts([{ product: newProduct.value, quantity: quantity }, ...products]);
								}}
								className={`btn flex col-span-3 items-center gap-x-2 bg-[#D76611] border-white text-white hover:bg-[#c65500] hover:border-white`}>
								Añadir
							</button>
							<div className="col-span-3 max-h-[200px] overflow-y-scroll">
								<table className="table table-zebra table-compact col-span-3 z-0 w-full">
									<thead>
										<tr>
											{products &&
												products.length > 0 &&
												["Nombre de Articulo", "Cantidad", ""]?.map((tableHeadItem, index) => (
													<th key={index} className="text-xs md:text-2xs lg:text-md">
														{tableHeadItem}
													</th>
												))}
										</tr>
									</thead>
									<tbody className="">
										{products &&
											products.length > 0 &&
											products.map((moviment, index) => (
												<TableRow
													key={moviment._id}
													tableRowsData={[
														moviment.product,
														moviment.quantity,

														<span className="flex items-center gap-x-1 ">
															<button
																onClick={() => {
																	setproducts(products.filter((product) => product !== moviment));
																}}
																className="z-10 block p-1 text-red-700 transition-all bg-red-100 border-2 border-white rounded-full active:bg-red-50 hover:scale-110 focus:outline-none focus:ring"
																type="button">
																<RiDeleteBin6Fill />
															</button>
														</span>,
													]}
												/>
											))}
									</tbody>
								</table>
							</div>

							{/* 							<Input
								onchange={(e) => setdescription(e.target.value)}
								title={"Información Adicional"}
								type="text"
								placeholder="Responsable por recibir la entrega: Fulano"
								name="pharmacyName"
							/> */}
							{typeOfMoviment === "ENTRADA S/ REMITO" && (
								<>
									<Input
										onchange={(e) => setSuplierName(e.target.value)}
										title={"Nombre de Proveedor"}
										type="text"
										placeholder="Del Pueblo"
										name="pharmacyName"
										isRequired="required"
									/>
									<Select
										onchange={(e) => {
											setTypeOfProduct(e.target.value);
										}}
										type="checkbox"
										title="Tipo de articulo"
										options={["PRODUCTO FINAL", "Otro"]}
										isRequired={"required"}
									/>
									{typeOfProduct === "PRODUCTO FINAL" && (
										<>
											<DatePicker
												onchange={(e) => {
													setexpiration(e.target.value);
												}}
												title={"Fecha de Vencimiento"}
												type="text"
												placeholder="dd/mm/aaaa"
												name="date"
												isRequired="required"
											/>
											<Input
												onchange={(e) => setlot(e.target.value)}
												title={"Producción"}
												type="text"
												name="category"
												placeholder="P25"
												isRequired="required"
											/>
										</>
									)}
								</>
							)}
							{typeOfMoviment === "ENTRADA" ? (
								<>
									<Input
										onchange={(e) => setSuplierName(e.target.value)}
										title={"Nombre de Proveedor"}
										type="text"
										placeholder="Del Pueblo"
										name="pharmacyName"
										isRequired="required"
									/>
									<Select
										onchange={(e) => {
											setTypeOfProduct(e.target.value);
										}}
										type="checkbox"
										title="Tipo de articulo"
										options={["PRODUCTO FINAL", "Otro"]}
										isRequired={true}
									/>
									{typeOfProduct === "PRODUCTO FINAL" && (
										<>
											<DatePicker
												onchange={(e) => {
													setexpiration(e.target.value);
												}}
												title={"Fecha de Vencimiento"}
												type="text"
												placeholder="dd/mm/aaaa"
												name="date"
												isRequired="required"
											/>
											<Input
												onchange={(e) => setlot(e.target.value)}
												title={"Producción"}
												type="text"
												name="category"
												placeholder="P25"
												isRequired="required"
											/>
										</>
									)}
									<Input
										onchange={(e) => setreferNumber(e.target.value)}
										title={"Nº de remito (últimos 4 digitos)"}
										type="number"
										name="category"
										placeholder="2501"
										isRequired="required"
										options={["ENTRADA", "SALIDA", "ENTRADA S/ REMITO"]}
									/>
								</>
							) : (
								typeOfMoviment === "SALIDA" && (
									<>
										<Input
											onchange={(e) => setreferNumber(e.target.value)}
											title={"Nº de remito (últimos 4 digitos)"}
											type="number"
											name="category"
											placeholder="2501"
											isRequired="required"
										/>
										<Input
											onchange={(e) => setAdresseeName(e.target.value)}
											title={"Nombre de destinatario"}
											type="text"
											placeholder="Del Pueblo"
											name="pharmacyName"
											isRequired="required"
										/>
										<Select
											onchange={(e) => setTypeOfAdressee(e.target.value)}
											title={"Tipo de destinatario"}
											type="text"
											placeholder="Del Pueblo"
											name="pharmacyName"
											isRequired="required"
											options={[
												"FARMACIA",
												"MEDICO",
												"APM",
												"DROGUERIA",
												"LABORATORIO",
												"DISPROFARMA",
												"TIENDA NUBE",
												"ATENCION MEDICO",
												"ATENCION PERSONAL",
												"COSMIATRA"
											]}
										/>
										{typeOfAdressee === "LABORATORIO" && (
											<Input
												onchange={(e) => setProductionOrder(e.target.value)}
												title={"Orden de producción"}
												type="text"
												placeholder="2024-152-001"
												name="pharmacyName"
												isRequired="required"
											/>
										)}
									</>
								)
							)}
						</div>
						<button
							className={`btn flex w-full col-span-3 items-center gap-x-2 bg-[#D76611] border-white text-white hover:bg-[#c65500] hover:border-white`}>
							Guardar
						</button>{" "}
					</form>
				</label>
			</label>
		</div>
	);
}
