import React from "react";
import VisitsGraphicProvider from "../contexts/visitsContext";
import DashboardSummary from "../pages/dashboard/DashboardSummary";
import RecipesGraphicProvider from "../contexts/recipesContext";
import DcPointsGraphicProvider from "../contexts/dcPointsContext";
import TransfersProvider from "../contexts/transfersContext";

export default function DashboardTemplate() {
  return (
    <VisitsGraphicProvider>
      <RecipesGraphicProvider>
        <TransfersProvider>
        <DcPointsGraphicProvider>
          <DashboardSummary />
        </DcPointsGraphicProvider>
        </TransfersProvider>
      </RecipesGraphicProvider>
    </VisitsGraphicProvider>
  );
}
