import React from 'react';

const FindExperts = () => {
    return (
        <div>
            <h2>FindExperts</h2>
        </div>
    );
};

export default FindExperts;