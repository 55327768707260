import { createContext, useCallback, useEffect, useState } from "react";
import { getMonthInSpanish } from "../Services/Grafics/getMonthInSpanish";
import { BACKEND_URL } from "../constants/constants";
import { transfers } from "../constants/transfers";
import { groupByLocationAndFilterByAPM } from "../Services/Grafics/transfers/totalTransfersPerLocationAndAPM";
import { groupByPharmacyAndFilterByAPM } from "../Services/Grafics/transfers/totalTransfersPerPharmacyAndAPM";
import { useAuth } from "../hooks/useAuth";
import { toast } from "react-toastify";
import { roles } from "../constants/roles";

export const transfersContext = createContext();

const TransfersProvider = ({ children }) => {
  const [chartData, setChartData] = useState([]);
  const [actualMonth, setActualMonth] = useState(new Date().getMonth());
  const [transfersCardPorcentage, setTransfersCardPorcentaje] = useState();
  const [transfersCardTitle, setTransfersCardTitle] = useState(
    `Transfers emitidos en el mes de ${getMonthInSpanish(new Date().getMonth())}`
  );
  const [transfersCardValue, setTransfersCardValue] = useState(false);
  const [dashboardStep, setDashboardStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [infoUnfiltered, setInfoUnfiltered] = useState([])
  const [pharmacyItems, setPharmacyItems] = useState([])
  const [locationItems, setLocationItems] = useState([])
  const {role} = useAuth()


  const fetchData = useCallback(async (month) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${BACKEND_URL}/transfers/getAll/${month + 2}`);
      const response = await res.json();

      const modelo = response.map((x) => ({
        ...x,
        apm:
          transfers.find(
            (y) => y.localidad.toLowerCase().trim() === x.localidad.toLowerCase().trim()
          )?.apm || "SIN APM",
      }));
      setInfoUnfiltered(modelo)
      const groupedData = modelo.reduce((acc, curr) => {
        const apm = curr.apm.trim();
        if (!acc[apm]) {
          acc[apm] = 0;
        }
        acc[apm] += curr.cantidad;
        return acc;
      }, {});

      const chartDataa = Object.entries(groupedData)
        .map(([name, value]) => ({
          name: name.length > 20 ? name.substring(0, 20) + "..." : name,
          value,
        }))
        .sort((a, b) => b.value - a.value);

      setChartData(chartDataa);
      setTransfersCardTitle(`Transfers emitidos en el mes de ${getMonthInSpanish(month)}`);
      
      const total = chartDataa.reduce((x, y) => x + y.value, 0);
      setTransfersCardValue(total);

      const anteriorMonthRequest = await fetch(`${BACKEND_URL}/transfers/getAll/${month + 1}`);
      const anteriorMonth = await anteriorMonthRequest.json();
      setTransfersCardPorcentaje(
        Math.ceil((total * 100) / anteriorMonth.reduce((x, y) => x + y.cantidad, 0)) - 100
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (dashboardStep === 0) {
      fetchData(actualMonth);
    }
  }, [actualMonth, dashboardStep, fetchData]);

  const changeMonth = useCallback((direction) => {
    const newMonth = actualMonth + direction;
    const currentMonth = new Date().getMonth();
    if (newMonth >= currentMonth - 2 && newMonth <= currentMonth) {
      setActualMonth(newMonth);
    }
  }, [actualMonth]);

  const previousClick = useCallback(() => changeMonth(-1), [changeMonth]);
  const nextClick = useCallback(() => changeMonth(1), [changeMonth]);

  const handleClick = (event) => {
    const { activeTooltipIndex } =
      event.chartX && event.chartY ? event : { activeTooltipIndex: -1 };

    if (activeTooltipIndex >= 0) {
      if(role.role === roles.SUPERADMIN || role.role === roles.COMERCIALLEADER || role.name.toUpperCase() === chartData[activeTooltipIndex].name.toUpperCase() || chartData[activeTooltipIndex].name.toUpperCase() === 'SIN FICHERO' || chartData[activeTooltipIndex].name.toUpperCase() === 'SIN APM' || chartData[activeTooltipIndex].name.toUpperCase() === 'GR JS') {

        const totalPharmacyTransfers =  groupByPharmacyAndFilterByAPM(
            infoUnfiltered,
            chartData[activeTooltipIndex].name,
          )
          const totalLocationTransfers =  groupByLocationAndFilterByAPM(
            infoUnfiltered,
            chartData[activeTooltipIndex].name,
          )
      setPharmacyItems(
        totalPharmacyTransfers
      );
      setLocationItems(
       totalLocationTransfers
      );
      setDashboardStep(dashboardStep + 1);

      const total = totalPharmacyTransfers.reduce((x, y)=>x + y.cantidad ,0)

      setTransfersCardValue(total);

    setTransfersCardTitle(
        `Productos Recetados de ${chartData[activeTooltipIndex].name} el mes de ${getMonthInSpanish(actualMonth)}`
      );
    } else{
      toast.error('Acceso denegado. Contactá al equipo de sistemas si creés que esto es un error.')
    }}
  
 
  };

  const contextValue = {
    chartData,
    actualMonth,
    transfersCardTitle,
    transfersCardPorcentage,
    transfersCardValue,
    dashboardStep,
    setDashboardStep,
    previousClick,
    nextClick,
    isLoading,
    pharmacyItems,
    locationItems,
    handleClick
  };

  return (
    <transfersContext.Provider value={contextValue}>
      {children}
    </transfersContext.Provider>
  );
};

export default TransfersProvider;
