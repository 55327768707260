export function groupByPharmacyAndFilterByAPM(array, apmName) {
  const filteredArray = array.filter(item => item.apm === apmName);
  const groupedByLocation = filteredArray.reduce((acc, curr) => {
    const farmacia = curr.farmacia.trim();
    if (!acc[farmacia]) {
      acc[farmacia] = {
        farmacia: farmacia,
        cantidad: 0,
        droguerias: new Set(),
        productos: new Set(),
        localidades: new Set()
      };
    }
    acc[farmacia].cantidad += curr.cantidad;
    acc[farmacia].droguerias.add(curr.drogueria);
    acc[farmacia].productos.add(curr.producto);
    return acc;
  }, {});

  // Convertimos el objeto resultante en un array y transformamos los Sets en arrays
  return Object.values(groupedByLocation).map(item => ({
    ...item,
    droguerias: Array.from(item.droguerias),
    productos: Array.from(item.productos),
  }));
}

