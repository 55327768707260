import React, { useContext } from "react";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import { BACKEND_URL } from "../../constants/constants";
import { ReloadDataContext } from "../../contexts/reloadDataContext";

const AcceptButton = ({ acceptURL, itemId }) => {
	const { setReloadPending } = useContext(ReloadDataContext);
	const accept = (_id) => {
		
		fetch(`${BACKEND_URL}${acceptURL}${_id}`, {
			method: "PUT",
			headers: {
				"content-type": "application/json",
			},
		})
			.then((res) => {
				toast.success(<span>Producto Aceptado con éxito.</span>);
				if (acceptURL === "/moviments/accept/") {
					setReloadPending((prev) => !prev);
				}
				res.json();
			})
			.catch(() => toast.error("Hubo un error al intentar aceptar el movimiento 😢"));
	};

	return (
		<button
			onClick={() => accept(itemId)}
			className="z-10 block p-1 text-green-700 transition-all bg-green-100 border-2 border-white rounded-full active:bg-green-50 hover:scale-110 focus:outline-none focus:ring"
			type="button">
			<FaCheck />
		</button>
	);
};

export default AcceptButton;
